import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import LoginPage from "./pages/LoginPage";
import RequireAuth from "./features/auth/RequireAuth";
import { routes } from "./utils/routes";
import "./App.css";

function App() {
  const generateRoutes = (routes) => {
    return routes.map((route, i) => {
      const { path, component, childRoutes } = route;

      return (
        <Route
          key={i}
          index={path === "default"}
          path={path === "default" ? "" : path}
          element={component}
        >
          {childRoutes && childRoutes.length > 0 && generateRoutes(childRoutes)}
        </Route>
      );
    });
  };
  return (
    <Routes>
      <Route index path="/login" element={<LoginPage />} />
      <Route element={<RequireAuth />}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="my-actions" />} />
          {generateRoutes(routes)}
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
