import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import "./NewJobPostingLayout.css";
import correctIcon from "../../assets/icons/Calendar_and_pagination_rounded.svg";
import { useGetJobByGuidQuery } from "../../features/MyActions/MyActionsSlice";

export default function NewJobPostingLayout({ section }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState(null);
  const [hasTeamMembers, setHasTeamMembers] = useState(false);

  const jobGuid = localStorage.getItem("jobGuid");

  const {
    data: jobData,
    isLoading: jobDataIsLoading,
    isFetching: jobDataIsFetching,
    isSuccess: jobDataIsSuccess,
    isError: jobDataIsError,
    error: jobDataError,
  } = useGetJobByGuidQuery(jobGuid, { skip: !jobGuid });

  useEffect(() => {
    if (jobData) {
      setJobDetails(jobData);
      setHasTeamMembers(jobData?.team?.members?.length > 0);
    }
  }, [jobData]);

  return (
    <div>
      <div className="custom-card newjob-nav-container">
        <h5
          className={`newjob-navlink ${
            location.pathname.includes(
              "/my-actions/new-job-posting/description"
            ) || location.pathname.includes("/job-posting/job/description")
              ? "active"
              : ""
          }`}
          onClick={() => {
            if (section) {
              navigate(
                `/job-posting/job/description/${jobGuid ? jobGuid : ""}`
              );
            } else {
              navigate(
                `/my-actions/new-job-posting/description/${
                  jobGuid ? jobGuid : ""
                }`
              );
            }
          }}
        >
          {location.pathname.includes("hiring-team") ||
          location.pathname.includes("interview-plan") ||
          location.pathname.includes("application-form") ||
          location.pathname.includes("automation") ||
          location.pathname.includes("compensation") ? (
            <img src={correctIcon} alt="done" width={25} height={25} />
          ) : (
            <p>1</p>
          )}
          Description
        </h5>
        <h5
          className={`newjob-navlink ${
            location.pathname.includes(
              "/my-actions/new-job-posting/hiring-team"
            ) || location.pathname.includes("/job-posting/job/hiring-team")
              ? "active"
              : ""
          }`}
          onClick={() => {
            if (section) {
              navigate(
                `/job-posting/job/hiring-team/${jobGuid ? jobGuid : ""}`
              );
            }

            if (!section && jobGuid)
              navigate(`/my-actions/new-job-posting/hiring-team/${jobGuid}`);
            else return;
          }}
        >
          {location.pathname.includes("interview-plan") ||
          location.pathname.includes("application-form") ||
          location.pathname.includes("automation") ||
          location.pathname.includes("compensation") ? (
            <img src={correctIcon} alt="done" width={25} height={25} />
          ) : (
            <p>2</p>
          )}
          Hiring Team
        </h5>
        <h5
          className={`newjob-navlink ${
            location.pathname.includes(
              "/my-actions/new-job-posting/interview-plan"
            ) || location.pathname.includes("/job-posting/job/interview-plan")
              ? "active"
              : ""
          }`}
          onClick={() => {
            if (!hasTeamMembers) {
              return;
            }
            if (section) {
              navigate(
                `/job-posting/job/interview-plan/${jobGuid ? jobGuid : ""}`
              );
            }
            if (!section && jobGuid) {
              navigate(`/my-actions/new-job-posting/interview-plan/${jobGuid}`);
            } else return;
          }}
        >
          {location.pathname.includes("application-form") ||
          location.pathname.includes("automation") ||
          location.pathname.includes("compensation") ? (
            <img src={correctIcon} alt="done" width={25} height={25} />
          ) : (
            <p>3</p>
          )}
          Interview Plan
        </h5>
        <h5
          className={`newjob-navlink ${
            location.pathname.includes(
              "/my-actions/new-job-posting/application-form/"
            ) || location.pathname.includes("/job-posting/job/application-form")
              ? "active"
              : ""
          }`}
          onClick={() => {
            if (!hasTeamMembers) {
              return;
            }
            if (section) {
              navigate(
                `/job-posting/job/application-form/${
                  jobGuid ? jobGuid : ""
                }/document-upload`
              );
            }
            if (!section && jobGuid) {
              navigate(
                `/my-actions/new-job-posting/application-form/${jobGuid}/document-upload`
              );
            } else return;
          }}
        >
          {location.pathname.includes("automation") ||
          location.pathname.includes("compensation") ? (
            <img src={correctIcon} alt="done" width={25} height={25} />
          ) : (
            <p>4</p>
          )}
          Application Form
        </h5>
        {jobDetails?.isAutomated &&
          jobDetails?.applicationQuestions.length > 0 && (
            <h5
              className={`newjob-navlink ${
                location.pathname.includes(
                  "/my-actions/new-job-posting/automation"
                ) || location.pathname.includes("/job-posting/job/automation")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                if (!hasTeamMembers) {
                  return;
                }
                if (section) {
                  navigate(
                    `/job-posting/job/automation/${jobGuid ? jobGuid : ""}`
                  );
                }

                if (!section && jobGuid) {
                  navigate(`/my-actions/new-job-posting/automation/${jobGuid}`);
                } else return;
              }}
            >
              {location.pathname.includes("compensation") ? (
                <img src={correctIcon} alt="done" width={25} height={25} />
              ) : (
                <p>5</p>
              )}
              Automation
            </h5>
          )}

        <h5
          className={`newjob-navlink ${
            location.pathname.includes(
              "/my-actions/new-job-posting/compensation"
            ) || location.pathname.includes("/job-posting/job/compensation")
              ? "active"
              : ""
          }`}
          onClick={() => {
            if (!hasTeamMembers) {
              return;
            }
            if (section) {
              navigate(
                `/job-posting/job/compensation/${jobGuid ? jobGuid : ""}`
              );
            }

            if (!section && jobGuid) {
              navigate(`/my-actions/new-job-posting/compensation/${jobGuid}`);
            } else return;
          }}
        >
          <p>6</p>Compensation
        </h5>
      </div>
      <Outlet />
    </div>
  );
}
