import { createEntityAdapter, nanoid } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const emailTemplatesAdapter = createEntityAdapter();
const emailTemplateAdapter = createEntityAdapter({
  selectId: (template) => template?.guid,
});
const placeholderAdapter = createEntityAdapter({
  selectId: (placeholder) => placeholder.guid,
});

const emailTemplatesInitialState = emailTemplatesAdapter.getInitialState();
const emailTemplateInitialState = emailTemplateAdapter.getInitialState();
const placeholderInitialState = placeholderAdapter.getInitialState();

export const settingsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmailTemplates: builder.query({
      query: ({ filters, query, page, pageSize }) =>
        `/email-templates?query=${query}&page=${page}&pageSize=${pageSize}&${filters}`,
      transformResponse: (response) => {
        const loadedTemplates = { ...response, id: nanoid() };
        return emailTemplatesAdapter.upsertOne(
          emailTemplatesInitialState,
          loadedTemplates
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "EmailTemplates", id: "LIST" }];
        } else {
          return [
            { type: "EmailTemplates", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "EmailTemplates",
              id,
            })),
          ];
        }
      },
    }),
    getEmailTemplateById: builder.query({
      query: (guid) => `/email-templates/${guid}`,
      transformResponse: (response) => {
        const loadedTemplate = response;
        return emailTemplateAdapter.setOne(
          emailTemplateInitialState,
          loadedTemplate
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "EmailTemplate", id: arg }];
        } else {
          return [{ type: "EmailTemplate", id: result?.guid }];
        }
      },
    }),
    getEmailTemplatePlaceholders: builder.query({
      query: (query) =>
        `/email-templates/placeholders?${query ? `Query=${query}` : ""}`,
      transformResponse: (response) => {
        const loadedPlaceholders = response;
        return placeholderAdapter.setAll(
          placeholderInitialState,
          loadedPlaceholders
        );
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "Placeholders", id: "LIST" }];
        } else {
          return [
            { type: "Placeholders", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "Placeholders",
              id,
            })),
          ];
        }
      },
    }),
    addEmailTemplate: builder.mutation({
      query: (data) => ({
        url: `/email-templates`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "EmailTemplates" },
        { type: "EmailTemplate" },
      ],
    }),
    updateEmailTemplate: builder.mutation({
      query: ({ guid, data }) => ({
        url: `/email-templates/${guid}`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "EmailTemplates" },
        { type: "EmailTemplate" },
      ],
    }),
  }),
});

export const {
  useGetEmailTemplatesQuery,
  useGetEmailTemplateByIdQuery,
  useGetEmailTemplatePlaceholdersQuery,
  useAddEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
} = settingsSlice;
