import CandidatePipeline from "../features/CandidatePipeline/CandidatePipeline";
import candidatesIcon from "../assets/icons/candidate pipeline.svg";
import dashboardIcon from "../assets/icons/dashboard.svg";
import settingsIcon from "../assets/icons/settings.svg";
import interviewIcon from "../assets/icons/interview plan.svg";
import jobIcon from "../assets/icons/job posting.svg";
import candidatesIconActive from "../assets/icons/candidate pipeline - active.svg";
import dashboardIconActive from "../assets/icons/dashboard - active.svg";
import settingsIconActive from "../assets/icons/settings - active.svg";
import interviewIconActive from "../assets/icons/interview plan - active.svg";
import jobIconActive from "../assets/icons/job posting - active.svg";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import EmailTemplate from "../features/ConfigurationPage/EmailTemplate/EmailTemplate";
import { Navigate } from "react-router-dom";
import NewJobPostingLayout from "../components/NewJobPostingLayout/NewJobPostingLayout";
import Description from "../features/NewJobPosting/Description/Description";
import InterviewPlan from "../features/NewJobPosting/InterviewPlan/InterviewPlan";
import ApplicationFormLayout from "../components/ApplicationFormLayout/ApplicationFormLayout";
import ApplicationQuestions from "../features/NewJobPosting/ApplicationForm/ApplicationQuestions/ApplicationQuestions";
import DocumentUpload from "../features/NewJobPosting/ApplicationForm/DocumentUpload/DocumentUpload";
import JobPosting from "../features/JobPosting/JobPosting";
import Automation from "../features/NewJobPosting/Automation/Automation";
import Compensation from "../features/NewJobPosting/Compensation/Compensation";
import CandidateView from "../features/CandidatePipeline/CandidateView/CandidateView";
import AssesmentPage from "../features/CandidatePipeline/AssesmentPage/AssesmentPage";
import ScheduleInterview from "../features/ScheduleInterview/ScheduleInterview";
import TalentPool from "../features/TalentPool/TalentPool";
import MyActions from "../features/MyActions/MyActions";
import HiringTeam from "../features/NewJobPosting/HiringTeam/HiringTeam";
import JobPostingLayout from "../components/JobPostingLayout/JobPostingLayout";
import ConfigurationLayout from "../components/ConfigurationLayout/ConfigurationLayout";
import CompanyProfile from "../features/ConfigurationPage/CompanyProfile/CompanyProfile";

export const routes = [
  {
    path: "my-actions",
    component: <DashboardLayout />,
    name: "My Actions",
    icon: dashboardIcon,
    iconActive: dashboardIconActive,

    childRoutes: [
      {
        path: "default",
        component: <MyActions />,
        name: "Details",
      },
      {
        path: "new-job-posting",
        component: <NewJobPostingLayout />,
        name: "Job Posting",

        childRoutes: [
          {
            path: "default",
            component: <Navigate to={"description"} />,
            name: "Description",
          },
          {
            path: "description/:jobGuid?",
            component: <Description />,
            name: "Description",
          },
          {
            path: "hiring-team/:jobGuid",
            component: <HiringTeam />,
            name: "Hiring Team",
          },
          {
            path: "interview-plan/:jobGuid",
            component: <InterviewPlan />,
            name: "Interview Plan",
          },
          {
            path: "application-form/:jobGuid",
            component: <ApplicationFormLayout />,
            name: "Application Form",
            childRoutes: [
              {
                path: "default",
                component: <Navigate to="document-upload" />,
                name: "Application Form",
              },
              {
                path: "document-upload",
                component: <DocumentUpload />,
                name: "Document Upload",
              },
              {
                path: "application-questions",
                component: <ApplicationQuestions />,
                name: "Application Questions",
              },
            ],
          },
          {
            path: "automation/:jobGuid",
            component: <Automation />,
            name: "Automation",
          },
          {
            path: "compensation/:jobGuid",
            component: <Compensation />,
            name: "Compensation",
          },
        ],
      },
    ],
  },
  {
    path: "job-candidates",
    name: "Job Candidates",
    icon: candidatesIcon,
    iconActive: candidatesIconActive,

    childRoutes: [
      {
        path: "",
        component: <CandidatePipeline />,
        name: "Job Candidates",
      },
      {
        path: ":name/:appliedJobGuid",
        component: <CandidateView />,
        name: "Candidate-view",
      },
      {
        path: "assesment",
        component: <AssesmentPage />,
        name: "Assesment",
      },
      {
        path: "schedule-interview",
        component: <ScheduleInterview />,
        name: "Assesment",
      },
    ],
  },
  {
    path: "talent-pool",
    component: <TalentPool />,
    name: "Talent Pool",
    icon: interviewIcon,
    iconActive: interviewIconActive,
  },
  {
    path: "job-posting",
    component: <JobPostingLayout />,
    name: "Job Postings",
    icon: jobIcon,
    iconActive: jobIconActive,

    childRoutes: [
      {
        path: "default",
        component: <JobPosting />,
        name: "Details",
      },
      {
        path: "job",
        component: <NewJobPostingLayout section={"job-posting"} />,
        name: "Job",

        childRoutes: [
          {
            path: "default",
            component: <Navigate to={"description"} />,
            name: "Description",
          },
          {
            path: "description/:jobGuid?",
            component: <Description />,
            name: "Description",
          },
          {
            path: "hiring-team/:jobGuid",
            component: <HiringTeam />,
            name: "Hiring Team",
          },
          {
            path: "interview-plan/:jobGuid",
            component: <InterviewPlan />,
            name: "Interview Plan",
          },
          {
            path: "application-form/:jobGuid",
            component: <ApplicationFormLayout />,
            name: "Application Form",
            childRoutes: [
              {
                path: "default",
                component: <Navigate to="document-upload" />,
                name: "Application Form",
              },
              {
                path: "document-upload",
                component: <DocumentUpload />,
                name: "Document Upload",
              },
              {
                path: "application-questions",
                component: <ApplicationQuestions />,
                name: "Application Questions",
              },
            ],
          },
          {
            path: "automation/:jobGuid",
            component: <Automation />,
            name: "Automation",
          },
          {
            path: "compensation/:jobGuid",
            component: <Compensation />,
            name: "Compensation",
          },
        ],
      },
    ],
  },
  {
    path: "configuration",
    component: <ConfigurationLayout />,
    name: "Configuration",
    icon: settingsIcon,
    iconActive: settingsIconActive,
    childRoutes: [
      {
        path: "default",
        component: <Navigate to="email-templates" />,
      },
      {
        path: "email-templates",
        component: <EmailTemplate />,
        name: "Email Template",
      },
      {
        path: "company-profile",
        component: <CompanyProfile />,
        name: "Company Profile",
      },
    ],
  },
];
