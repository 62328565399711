import React, { useState, useEffect } from "react";
import "./Automation.css";
import confirmIcon from "../../../assets/icons/confirm.svg";
import rejectIcon from "../../../assets/icons/reject.svg";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";
import { useGetJobByGuidQuery } from "../../MyActions/MyActionsSlice";
import {
  useMarkForAutomationMutation,
  useUnmarkFromAutomationMutation,
  useUpdateJobPostingMutation,
} from "../newJobPostingSlice";
import { JobStatuses } from "../../../enums/jobStatuses";
import EmailTemplateDropdown from "../../../components/EmailTemplateDropdown/EmailTemplateDropdown";

export default function Automation() {
  const { jobGuid } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [confirmEmailTemplate, setConfirmEmailTemplate] = useState(null);
  const [rejectEmailTemplate, setRejectEmailTemplate] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);

  const {
    data: jobData,
    isLoading: jobDataIsLoading,
    isFetching: jobDataIsFetching,
    isSuccess: jobDataIsSuccess,
    isError: jobDataIsError,
    error: jobDataError,
  } = useGetJobByGuidQuery(jobGuid, { skip: !jobGuid });

  useEffect(() => {
    if (jobData) {
      setQuestions(jobData.applicationQuestions);
      setJobDetails(jobData);
      setRejectEmailTemplate({
        value: jobData?.rejectEmailTemplate?.guid,
        label: jobData?.rejectEmailTemplate?.name,
      });
      setConfirmEmailTemplate({
        value: jobData?.confirmEmailTemplate?.guid,
        label: jobData?.confirmEmailTemplate?.name,
      });
    }
  }, [jobData]);

  const [markForAutomation, { isLoading: markForAutomationIsLoading }] =
    useMarkForAutomationMutation();
  const [unMarkFromAutomation, { isLoading: unmarkFormAutomationIsLoading }] =
    useUnmarkFromAutomationMutation();
  const [updateJobPosting, { isLoading: updateJobPostingIsLoading }] =
    useUpdateJobPostingMutation();

  const handleAutomation = async (data) => {
    try {
      let responsePromise;

      if (data.isAutomated) {
        responsePromise = markForAutomation({ guid: data.guid }).unwrap();
      } else {
        responsePromise = unMarkFromAutomation({ guid: data.guid }).unwrap();
      }

      toast.promise(responsePromise, {
        success: `${
          data.isAutomated
            ? "Question automated sucessfully...!"
            : "Question removed from automation...!"
        }`,
        loading: "Loading",
        error: "Something went wrong...!",
      });
      await responsePromise;
    } catch (error) {
      toast.error(error?.data?.Message);
    }
  };

  const handleSave = async (saveOnly) => {
    if (!confirmEmailTemplate || !confirmEmailTemplate?.value) {
      toast.error("Confirmation Email Template is required...!");
      return;
    }
    if (!rejectEmailTemplate || !rejectEmailTemplate?.value) {
      toast.error("Reject Email Template is required...!");
      return;
    }

    const isSetForAutomation = questions.some(
      (question) => question.isAutomated === true
    );
    if (!isSetForAutomation) {
      toast.error("At least one question should be set for automation...!");
      return;
    }

    try {
      const responsePromise = updateJobPosting({
        guid: jobGuid,
        data: {
          confirmEmailTemplateGuid: confirmEmailTemplate?.value,
          rejectEmailTemplateGuid: rejectEmailTemplate?.value,
          // isAutomated: true,
        },
      }).unwrap();

      toast.promise(responsePromise, {
        success: "Automation updated successfully",
        loading: "Loading",
        error: "Something went wrong",
      });

      if (!saveOnly) {
        navigate(`/my-actions/new-job-posting/compensation/${jobGuid}`);
      }
      await responsePromise;
    } catch (error) {
      toast.error(error?.data?.Message);
    }
  };

  return (
    <div className="automation">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {jobDataIsLoading && <LoadingSpinner />}
      <div className="custom-card automation-container">
        <div className="row justify-content-between automation-box">
          <div className="col-8 pt-2">
            <table className="table">
              <thead>
                <tr>
                  <th>Application Questions</th>
                  <th className="second-column">Required Answers</th>
                  <th className="check-column">Set for Automation</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question, index) => (
                  <tr key={question.guid}>
                    <td>
                      {++index}. {question.question}
                    </td>
                    <td className="second-column">
                      <input
                        className="form-control"
                        type="text"
                        disabled
                        value={question.requiredAnswer ? "Yes" : "No"}
                      />
                    </td>
                    <td className="check-column">
                      <div className="form-check form-switch ms-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={question.isAutomated}
                          onChange={(e) =>
                            handleAutomation({
                              isAutomated: e.target.checked,
                              guid: question.guid,
                            })
                          }
                          disabled={
                            markForAutomationIsLoading ||
                            unmarkFormAutomationIsLoading
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-4 confirmation-container">
            <div className="row">
              <div className="d-flex flex-column">
                <div className="d-flex  gap-md-1 gap-xl-2 align-items-center mb-2 p-0 justify-content-center">
                  <img src={confirmIcon} width={20} height={20} alt="confirm" />
                  <p>Auto - Confirm Application</p>
                </div>
                <EmailTemplateDropdown
                  selectedEmailTemplate={confirmEmailTemplate}
                  setSelectedEmailTemplate={setConfirmEmailTemplate}
                />
              </div>
            </div>
            <div className="row">
              <div className="d-flex flex-column">
                <div className="d-flex  gap-md-1 gap-xl-2 align-items-center mb-2 p-0 justify-content-center">
                  <img src={rejectIcon} width={20} height={20} alt="reject" />
                  <p>Auto - Reject Application</p>
                </div>
                <EmailTemplateDropdown
                  selectedEmailTemplate={rejectEmailTemplate}
                  setSelectedEmailTemplate={setRejectEmailTemplate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <button
              className="btn back-btn"
              onClick={() =>
                navigate(
                  `/my-actions/new-job-posting/application-form/${jobGuid}/document-upload`
                )
              }
            >
              Back
            </button>
          </div>
          <div className="col-auto">
            <button
              className="btn back-btn me-1"
              onClick={() => handleSave(true)}
            >
              Save
            </button>
            <button
              className="btn continue-btn"
              onClick={() => handleSave(false)}
            >
              Save & Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
