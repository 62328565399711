import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "../CompanyProfile/CompanyProfile.css";
import { ReactComponent as EditIcon } from "../../../assets/icons/editIconColorful.svg";
import companyLogo from "../../../assets/images/intellimorphlogo.png";
import {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from "../../commonDataSlice/companySlice";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { format } from "date-fns";

const CompanyProfile = () => {
  const navigate = useNavigate();
  const [editCompanyDetails, setEditCompanyDetails] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    guid: "",
    name: "",
    description: "",
    specialties: "",
    location: "",
    webUrl: "",
    industry: "",
    companySize: "",
    logo: "",
    foundedOn: "",
  });

  const {
    data: companyData,
    isLoading: companyDataIsLoading,
    isError: companyDataIsError,
    error: companyDataError,
  } = useGetCompanyQuery();

  const [updateCompany, { isLoading: isUpdating }] = useUpdateCompanyMutation();

  useEffect(() => {
    if (companyData) {
      setCompanyDetails(companyData);
    }
    if (companyDataIsError) {
      toast.error(
        companyDataError?.data?.Message || companyDataError?.data?.title
      );
    }
  }, [companyData, companyDataIsError, companyDataError]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails({ ...companyDetails, [name]: value });
  };

  const validateFields = () => {
    for (let key in companyDetails) {
      if (companyDetails[key] === "") {
        toast.error("Please fill in all required fields", { duration: 2000 });
        return false;
      }
    }
    return true;
  };

  const handleSave = async () => {
    if (!validateFields()) return;

    try {
      await updateCompany(companyDetails).unwrap();
      toast.success("Company details updated successfully!");
      setEditCompanyDetails(false);
    } catch (error) {
      toast.error("Failed to update company details. Please try again.");
      console.error("Update error:", error);
    }
  };

  const handleDateChange = (arg) => {
    const foundedOn = format(new Date(arg?.value), "yyyy-MM-dd");
    handleInputChange({
      target: {
        name: "foundedOn",
        value: foundedOn,
      },
    });
  };

  return (
    <div>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: { primary: "#180080", secondary: "#f3f3f3" },
          },
        }}
      />
      {companyDataIsLoading && <LoadingSpinner />}
      <div className="candicate-pipeline-content mt-2 p-3">
        <div className="mx-auto" style={{ width: "99%" }}>
          <div className="d-flex justify-content-end mb-1">
            <EditIcon
              className="company-profile-edit-icon"
              onClick={() => setEditCompanyDetails(!editCompanyDetails)}
            />
          </div>
          <div className="company-profile-header">
            <div className="company-logo">
              <img
                src={companyLogo || companyDetails?.logo}
                alt={companyDetails?.name}
              />
            </div>
            <div className="company-description">
              {editCompanyDetails ? (
                <textarea
                  rows={4}
                  name="description"
                  value={companyDetails?.description}
                  onChange={handleInputChange}
                  className=" form-control w-100"
                ></textarea>
              ) : (
                <p>{companyDetails?.description}</p>
              )}
            </div>
          </div>

          <div className="company-profile-container">
            <div className="company-info">
              <div className="company-info-title">Website</div>
              <div className="company-info-description">
                {editCompanyDetails ? (
                  <input
                    type="text"
                    name="webUrl"
                    value={companyDetails?.webUrl}
                    onChange={handleInputChange}
                    className="company-input form-control"
                  />
                ) : (
                  <a href={companyDetails?.webUrl} className="website-link">
                    {companyDetails?.webUrl}
                  </a>
                )}
              </div>
            </div>
            <div className="company-info">
              <div className="company-info-title">Industry</div>
              <div className="company-info-description">
                {editCompanyDetails ? (
                  <input
                    type="text"
                    name="industry"
                    value={companyDetails?.industry}
                    onChange={handleInputChange}
                    className="company-input form-control"
                  />
                ) : (
                  companyDetails?.industry
                )}
              </div>
            </div>
            <div className="company-info">
              <div className="company-info-title">Company size</div>
              <div className="company-info-description">
                {editCompanyDetails ? (
                  <input
                    type="text"
                    name="companySize"
                    value={companyDetails?.companySize}
                    onChange={handleInputChange}
                    className="company-input form-control"
                  />
                ) : (
                  companyDetails?.companySize
                )}
              </div>
            </div>
            <div className="company-info">
              <div className="company-info-title">Founded</div>
              <div className="company-info-description">
                {editCompanyDetails ? (
                  <DatePickerComponent
                    id="datepicker"
                    cssClass="company-input form-control"
                    value={new Date(
                      companyDetails?.foundedOn
                    )?.toLocaleDateString("en-GB")}
                    format="dd-MM-yyyy"
                    placeholder="Enter Company Founded  date"
                    onChange={(arg) => {
                      handleDateChange(arg);
                    }}
                  />
                ) : (
                  new Date(companyDetails?.foundedOn).toLocaleDateString(
                    "en-GB"
                  )
                )}
              </div>
            </div>
            <div className="company-info">
              <div className="company-info-title">Specialties</div>
              <div className="company-info-description">
                {editCompanyDetails ? (
                  <input
                    type="text"
                    name="specialties"
                    value={companyDetails?.specialties}
                    onChange={handleInputChange}
                    className="company-input form-control"
                  />
                ) : (
                  companyDetails?.specialties
                )}
              </div>
            </div>
            <div className="company-info">
              <div className="company-info-title">Location</div>
              <div className="company-info-description">
                {editCompanyDetails ? (
                  <input
                    type="text"
                    name="location"
                    value={companyDetails?.location}
                    onChange={handleInputChange}
                    className="company-input form-control"
                  />
                ) : (
                  companyDetails?.location
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between pt-4">
            <button
              className="back-btn p-1"
              onClick={() => navigate("/configuration/email-templates")}
            >
              Back
            </button>
            {editCompanyDetails && (
              <button
                className="continue-btn p-1"
                onClick={handleSave}
                disabled={isUpdating}
              >
                {isUpdating ? "Saving..." : "Save"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
