import { createEntityAdapter, nanoid } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const adapter = createEntityAdapter({
  selectId: (notification) => notification?.guid,
});

const initialState = adapter.getInitialState();

export const NotificationDataSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUserNotifications: builder.query({
      query: () => `/notifications`,
      transformResponse: (response) => {
        const loadedData = response;
        return adapter.setAll(initialState, loadedData);
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "UserNotifications", id: "LIST" }];
        } else {
          return [
            { type: "UserNotifications", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "UserNotifications",
              id: id.guid,
            })),
          ];
        }
      },
    }),
    notificationMarkAsRead: builder.mutation({
      query: ({guid}) => ({
        url: `/notifications/${guid}:mark-as-seen`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "UserNotifications" }],
    }),
  }),
});

export const { useGetCurrentUserNotificationsQuery, useNotificationMarkAsReadMutation } = NotificationDataSlice;
