import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import "./Layout.css";
import BreadcrumbsComponent from "../common/Breadcrumbs/BreadcrumbsComponent";
import NotificationPanel from "../NotificationPanel/NotificationPanel";

const Layout = () => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [notificationCount, setNotificationCount] = useState("");

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const toggleNotificationModal = () => {
    setShowNotificationModal(!showNotificationModal);
  };

  return (
    <div className={`layout ${!isSidebarCollapsed ? "collapsed" : ""}`}>
      <div className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}>
        <Sidebar
          toggleSidebar={toggleSidebar}
          isCollapsed={isSidebarCollapsed}
        />
      </div>
      <div className="navbar">
        <BreadcrumbsComponent
          notificationCount={notificationCount}
          toggleNotificationModal={toggleNotificationModal}
        />
      </div>
      <div className="main">
        <NotificationPanel
          showNotificationModal={showNotificationModal}
          closeNotificationModel={toggleNotificationModal}
          notificationCount={setNotificationCount}
        />
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
