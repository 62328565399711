import React, { useState, useEffect } from "react";
import FilterIcon from "../../assets/icons/filter.svg";
import searchIcon from "../../assets/icons/search.svg";
import pdfIcon from "../../assets/icons/pdf.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import DataTable from "../../components/common/DataTable/DataTable";
import FilterModal from "../../components/common/FilterModal/FilterModal";
import "./TalentPool.css";
import {
  useGetTalentPoolCandidatesQuery,
  useRemoveCandidateFromCandidatePoolMutation,
} from "./TalentPoolSlice";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import DeleteConfirmationModal from "../../components/common/DeleteConfirmationModal/DeleteConfirmationModal";
import SmallLoadingSpinner from "../../components/common/SmallLoadingSpinner/SmallLoadingSpinner";
import { useHandleAttachmentDownload } from "../../hooks/useHandleAttachmentDownload";

const TalentPool = () => {
  const { handleAttachmentDownload } = useHandleAttachmentDownload();

  const [pageSize, setPageSize] = useState(10);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [candidateGuid, setCandidateGuid] = useState("");
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [talentPoolCandidates, setTalentPoolCandidates] = useState([]);
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [attachmentLinks, setAttachmentLinks] = useState([]);
  const [attachmentFileName, setAttachmentFileName] = useState("");

  const {
    data: talentPoolCandidatesData,
    isLoading: talentPoolCandidatesDataIsLoading,
    isFetching: talentPoolCandidatesDataIsFetching,
    isSuccess: talentPoolCandidatesDataIsSuccess,
    isError: talentPoolCandidatesDataIsError,
    error: talentPoolCandidatesDataError,
  } = useGetTalentPoolCandidatesQuery();

  const [
    removeCandidateFromCandidatePool,
    {
      isLoading: removeCandidateFromCandidatePoolIsLoading,
      isSuccess: removeCandidateFromCandidatePoolIsSuccess,
    },
  ] = useRemoveCandidateFromCandidatePoolMutation();

  useEffect(() => {
    if (talentPoolCandidatesData) {
      const data = talentPoolCandidatesData?.ids?.map(
        (id) => talentPoolCandidatesData?.entities[id]
      );
      setTalentPoolCandidates(data);
    }
    if (talentPoolCandidatesDataError) {
      toast.error(talentPoolCandidatesDataError?.data?.Message, {
        duration: 4000,
      });
      return;
    }
  }, [talentPoolCandidatesData, talentPoolCandidatesDataError]);

  useEffect(() => {
    toast.remove();
  }, []);

  function closeModal() {
    setShowDeleteModel(false);
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (talentPoolCandidatesData) {
      const filteredData = talentPoolCandidates.filter((candidate) =>
        Object.values(candidate).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredCandidates(filteredData);
    }
  }, [searchTerm, talentPoolCandidatesData]);

  const removeCandidateFromTalentPoolOnClick = async () => {
    try {
      const response = await removeCandidateFromCandidatePool({
        guid: candidateGuid,
      }).unwrap();
      if (response?.error) {
        toast.error(response?.error?.data?.Message || "Something Went wrong", {
          duration: 4000,
        });
        return;
      }
      closeModal();
      toast.success("Candidate Removed Successfully", {
        duration: 2000,
      });
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 4000,
      });
    }
  };

  const talentPoolDatacolumns = [
    {
      key: "Name",
      field: "name",
      headerText: "Candidate",
      width: "120",
      customAttributes: {
        class: "candidate-first-column",
      },
    },
    { key: "Email", field: "email", headerText: "Email", width: "180" },
    {
      key: "Contact Number",
      field: "contact",
      headerText: "Contact Number",
      width: "150",
    },
    {
      key: "score",
      field: "score",
      headerText: "Score",
      width: "100",
    },
    {
      key: "Feedback",
      field: "feedback",
      headerText: "Feedback",
      width: "160",
    },
    {
      key: "resume",
      field: "resume",
      headerText: "Resume",
      width: "110",
      alignment: "center",
      template: (props) => (
        <div className="position-relative">
          <img
            src={pdfIcon}
            alt="resume"
            width={25}
            height={25}
            onClick={() => handleAttachmentDownload(props.attachment)}
            style={{ cursor: "pointer" }}
          />
          {isFileDownloading &&
            attachmentFileName === props.attachment?.fileName && (
              <SmallLoadingSpinner />
            )}
        </div>
      ),
    },
    {
      key: "",
      field: "",
      headerText: "Action",
      width: "100",
      template: (props) => (
        <div className="option-icons-container">
          <div className="d-flex">
            <div>
              <DeleteIcon
                width={18}
                height={18}
                onClick={() => {
                  setCandidateGuid(props?.candidateGuid);
                  setShowDeleteModel(true);
                }}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const filterOptions = [
    { name: "Name", type: "text" },
    { name: "Job Applied", type: "text" },
    { name: "Interview Stage", type: "text" },
    { name: "Date", type: "date" },
    { name: "Interview Status", type: "text" },
  ];

  return (
    <div>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="talent-pool-content mt-2">
        {talentPoolCandidatesDataIsFetching ? <LoadingSpinner /> : ""}
        <div className="row gap-2 justify-content-between align-items-center mb-3 pt-3">
          <div className="col-12 col-md-2 border-2 px-4">
            <div className="d-flex">
              <div>
                <select
                  className="border-0 page-selector"
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <option value={10}>Display 10 Candidates</option>
                  <option value={20}>Display 20 Candidates</option>
                  <option value={50}>Display 50 Candidates</option>
                  <option value={100}>Display 100 Candidates</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-auto border-2 d-flex py-3 px-4">
            <div style={{ position: "relative" }}>
              <div className="search-icon">
                <img src={searchIcon} alt="Search" />
              </div>
              <div className="px-2">
                <input
                  id="file-search-input"
                  type="text"
                  className="form-control custom-input w-100 pl-3"
                  placeholder="Search Candidate"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div>
              <button onClick={() => {}} className="btn p-0">
                <img src={FilterIcon} alt="filter" />
              </button>
            </div>
          </div>
        </div>
        <FilterModal
          showModal={showFilterModal}
          closeModal={closeModal}
          filterOptions={filterOptions}
        />
        <div className="row">
          <div>
            <DataTable
              data={searchTerm ? filteredCandidates : talentPoolCandidates}
              columns={talentPoolDatacolumns}
              pageSize={pageSize}
              selectable={true}
            />
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        showModal={showDeleteModel}
        closeModal={closeModal}
        type={"candidate"}
        confirmDelete={removeCandidateFromTalentPoolOnClick}
      />
    </div>
  );
};

export default TalentPool;
