import React, { useEffect, useState } from "react";
import "../CandidateView/CandidateView.css";
import SettingsPageLayout from "../../../components/SettingsPageLayout/SettingsPageLayout";
import CandidateViewSidebar from "../../../components/CandidateViewSidebar/CandidateViewSidebar";
import { useLocation, useParams } from "react-router-dom";
import ScheduleInterview from "./ScheduleInterview/ScheduleInterview";
import InterviewKit from "./InterviewKit/InterviewKit";
import PerformanceReview from "./PerformanceReview/PerformanceReview";
import {
  useGetJobInterviewPipelineStagesQuery,
  useGetCandidateAppliedJobQuery,
} from "../CandidatePipelineSlice";
import { format, parseISO } from "date-fns";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";

const CandidateView = () => {
  const { appliedJobGuid } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobGuid = searchParams.get("jobGuid");
  const targetPipelineStageGuid = searchParams.get("targetPipelineStageGuid");
  const targetPipelineStageSection = searchParams.get(
    "targetPipelineStageSection"
  );

  const [active, setActive] = useState("ScheduleInterview");
  const [jobInterviewPipelineStages, setJobInterviewPipelineStages] = useState(
    []
  );
  const [candidateData, setCandidateData] = useState({});
  const [pipelineStageData, setPipelineStageData] = useState({});
  const [activeLink, setActiveLink] = useState("");

  const {
    data: candidateAppliedJobData,
    isLoading: candidateAppliedJobDataIsLoading,
    isFetching: candidateAppliedJobDataIsFetching,
    isSuccess: candidateAppliedJobDataIsSuccess,
    isError: candidateAppliedJobDataIsError,
    error: candidateAppliedJobDataError,
    refetch: candidateAppliedJobDataRefetch,
  } = useGetCandidateAppliedJobQuery(appliedJobGuid);

  const {
    data: jobInterviewPipelineStagesData,
    isLoading: jobInterviewPipelineStagesDataIsLoading,
    isFetching: jobInterviewPipelineStagesDataIsFetching,
    isSuccess: jobInterviewPipelineStagesDataIsSuccess,
    isError: jobInterviewPipelineStagesDataIsError,
    error: jobInterviewPipelineStagesDataError,
    refetch: jobInterviewPipelineStagesDataRefetch,
  } = useGetJobInterviewPipelineStagesQuery(jobGuid);

  const dateFomatter = (dateString) => {
    return dateString ? format(parseISO(dateString), "yyyy-MM-dd") : null;
  };

  useEffect(() => {
    if (candidateAppliedJobData) {
      const data = {
        ...candidateAppliedJobData,
        candidate: {
          ...candidateAppliedJobData.candidate,
          fullName:
            candidateAppliedJobData.candidate?.firstName +
            " " +
            candidateAppliedJobData.candidate?.lastName,
        },
      };
      setCandidateData(data);
    }
  }, [candidateAppliedJobData]);

  useEffect(() => {
    if (targetPipelineStageSection) {
      setActive(targetPipelineStageSection);
    }
  }, [targetPipelineStageSection]);

  useEffect(() => {
    if (jobInterviewPipelineStagesData) {
      setJobInterviewPipelineStages(jobInterviewPipelineStagesData);
      // setStageName(jobInterviewPipelineStagesData[0]?.name);
      // setInitialGuid(jobInterviewPipelineStagesData[0]?.guid);
    }
  }, [jobInterviewPipelineStagesData]);

  useEffect(() => {
    if (candidateAppliedJobData && activeLink) {
      let stageData =
        candidateAppliedJobData.appliedJobInterviewPipelineStages?.filter(
          (item) => item?.jobInterviewPipelineStage?.name === activeLink
        );

      const [data] = stageData;
      if (data) {
        stageData = {
          ...data,
          jobInterviewPipelineStage: data.jobInterviewPipelineStage?.name,
          dateAssigned: dateFomatter(data.dateAssigned),
          status: data.status?.name,
          statusGuid: data.status?.guid,
        };
      }
      setPipelineStageData(stageData);
    }
  }, [candidateAppliedJobData, activeLink]);

  return (
    <div className="container-fluid">
      {candidateAppliedJobDataIsFetching && <LoadingSpinner />}
      <div className="row gap-1">
        <CandidateViewSidebar
          active={active}
          setActive={setActive}
          data={candidateData}
        />
        <div className="col-sm-12 col-md candidate-view-card-container">
          <div className="row mb-2">
            <div className="col">
              <div className="nav-link-container">
                <SettingsPageLayout
                  jobStages={candidateData?.appliedJobInterviewPipelineStages}
                  activeLink={activeLink}
                  setActiveLink={setActiveLink}
                />
              </div>
            </div>
          </div>

          {active === "InterviewKit" ? (
            <InterviewKit stageGuid={pipelineStageData?.guid} />
          ) : active === "PerformanceReview" ? (
            <PerformanceReview
              activatedStage={activeLink}
              data={pipelineStageData}
            />
          ) : (
            <ScheduleInterview
              activatedStage={activeLink}
              data={pipelineStageData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateView;
