import React, { useEffect, useState } from "react";
import "./Compensation.css";
import { useUpdateJobPostingMutation } from "../newJobPostingSlice";
import { JobStatuses } from "../../../enums/jobStatuses";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useGetJobByGuidQuery } from "../../MyActions/MyActionsSlice";

export default function Compensation() {
  const { jobGuid } = useParams();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState(null);
  const [minSalary, setMinSalary] = useState(0);
  const [maxSalary, setMaxSalary] = useState(0);

  const {
    data: jobData,
    isLoading: jobDataIsLoading,
    isFetching: jobDataIsFetching,
    isSuccess: jobDataIsSuccess,
    isError: jobDataIsError,
    error: jobDataError,
  } = useGetJobByGuidQuery(jobGuid, { skip: !jobGuid });

  const [isSalaryDisplayOnJobPosting, setIsSalaryDisplayOnJobPosting] =
    useState(false);
  const [isSalaryDisplayOnJobBoard, setIsSalaryDisplayOnJobBoard] =
    useState(false);

  useEffect(() => {
    if (jobData) {
      setJobDetails(jobData);
      setMinSalary(parseFloat(jobData?.minSalary ?? 0));
      setMaxSalary(parseFloat(jobData?.maxSalary ?? 0));
      setIsSalaryDisplayOnJobPosting(jobData?.isSalaryDisplayOnJobPosting);
      setIsSalaryDisplayOnJobBoard(jobData?.isSalaryDisplayOnJobBoard);
    }
  }, [jobData]);

  const [updateJobPosting, { isLoading: updateJobPostingIsLoading }] =
    useUpdateJobPostingMutation();

  const handleSave = async (saveOnly) => {
    if (!minSalary || minSalary === 0) {
      toast.error("Minimum salary is required...!");
      return;
    }
    if (!maxSalary || maxSalary === 0) {
      toast.error("Maximum salary is required...!");
      return;
    }

    if (maxSalary < minSalary) {
      toast.error(
        "Maxium salary should be greater than the minumum salary...!"
      );
      return;
    }

    if (maxSalary < 0 || minSalary < 0) {
      toast.error("Salary cannot be a negetive value...!");
      return;
    }

    try {
      const responsePromise = updateJobPosting({
        guid: jobGuid,
        data: {
          isSalaryDisplayOnJobPosting,
          isSalaryDisplayOnJobBoard,
          jobStatusId: JobStatuses.OPEN,
          minSalary,
          maxSalary,
        },
      }).unwrap();

      toast.promise(responsePromise, {
        success: "Compensation updated successfully",
        loading: "Loading",
        error: "Something went wrong",
      });

      if (!saveOnly) {
        navigate(`/job-posting`);
      }
      await responsePromise;
      toast.success("Congrats, Job Posted Successfully...!");
    } catch (error) {
      toast.error(error?.data?.Message);
    }
  };

  return (
    <div className="compensation">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="custom-card compensation-container">
        <div className="row align-items-center">
          <div className="col-3">
            <p>Compensation</p>
          </div>
          <div className="col-6 d-flex align-items-center gap-2">
            <div className="form-floating">
              <input
                id="minSalary"
                className="form-control"
                type="number"
                placeholder="Minimum salary Ex:- £ 60,000"
                value={minSalary}
                onChange={(e) => setMinSalary(parseFloat(e.target.value))}
                min={0}
              />
              <label htmlFor="minSalary">Minimum Salary</label>
            </div>
            -
            <div className="form-floating">
              <input
                id="maxSalary"
                className="form-control"
                type="number"
                placeholder="Maximum salary Ex:-£ 60,500"
                value={maxSalary}
                onChange={(e) => setMaxSalary(parseFloat(e.target.value))}
                min={0}
              />
              <label htmlFor="maxSalary">Maximum Salary</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Display on this job posting</p>
          </div>
          <div className="col-6 d-flex align-items-center gap-2">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isSalaryDisplayOnJobPosting}
                onChange={(e) =>
                  setIsSalaryDisplayOnJobPosting(e.target.checked)
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Display on job board</p>
          </div>
          <div className="col-6 d-flex align-items-center gap-2">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isSalaryDisplayOnJobBoard}
                onChange={(e) => setIsSalaryDisplayOnJobBoard(e.target.checked)}
              />
            </div>
          </div>
        </div>
        <div className="row btn-container">
          <div className="col">
            <button
              className="btn back-btn"
              onClick={() =>
                navigate(`/my-actions/new-job-posting/automation/${jobGuid}`)
              }
            >
              Back
            </button>
          </div>
          <div className="col-auto">
            <button
              className="btn back-btn me-2"
              onClick={() => handleSave(true)}
            >
              Save
            </button>
            <button
              className="btn continue-btn"
              onClick={() => handleSave(false)}
            >
              Save & Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
