import React, { useState, useEffect, useRef } from "react";
import "./ApplicationQuestions.css";
import deleteIcon from "../../../../assets/icons/deleteIcon.svg";
import toast, { Toaster } from "react-hot-toast";
import DeleteConfirmationModal from "../../../../components/common/DeleteConfirmationModal/DeleteConfirmationModal";
import AddStageModal from "../../../../components/AddStageModal/AddStageModal";
import { useNavigate, useParams } from "react-router-dom";
import { useGetJobByGuidQuery } from "../../../MyActions/MyActionsSlice";
import { nanoid } from "@reduxjs/toolkit";
import {
  useAddApplicationQuestionsMutation,
  useDeleteApplicationQuestionMutation,
  useUpdateJobPostingMutation,
} from "../../newJobPostingSlice";
import LoadingSpinner from "../../../../components/common/LoadingSpinner/LoadingSpinner";
import { JobStatuses } from "../../../../enums/jobStatuses";

export default function ApplicationQuestions() {
  const { jobGuid } = useParams();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState({
    guid: "",
    question: "",
    requiredAnswer: true,
    isNew: true,
  });
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [isAddNewQuestionVisible, setIsAddNewQuestionVisible] = useState(false);
  const isFirstRender = useRef(true);

  const {
    data: jobData,
    isLoading: jobDataIsLoading,
    isFetching: jobDataIsFetching,
    isSuccess: jobDataIsSuccess,
    isError: jobDataIsError,
    error: jobDataError,
  } = useGetJobByGuidQuery(jobGuid, { skip: !jobGuid });

  useEffect(() => {
    if (jobData) {
      setJobDetails(jobData);
      setQuestions(jobData.applicationQuestions);
    }
  }, [jobData]);

  const [addQuestions, { isLoading: addQuestionsIsLoading }] =
    useAddApplicationQuestionsMutation();
  const [deleteQuestion, { isLoading: deleteQuestionIsLoading }] =
    useDeleteApplicationQuestionMutation();

  const [updateJob, { isLoading: updateJobIsLoading }] =
    useUpdateJobPostingMutation();

  const [fields, setFields] = useState([
    "Do you consent for background check?",
    "Company",
    "Available date to start position?",
    "Expected Salary",
  ]);

  const [showModal, setShowModal] = useState(false);
  const [addShowModal, setAddShowModal] = useState(false);

  const handleAddNewStage = (value) => {
    const tempArray = [...fields];
    tempArray.push(value);
    setFields(tempArray);
    setAddShowModal(!addShowModal);
    toast.success("Field Added Successfully", {
      duration: 1500,
    });
  };

  const handleAddQuestion = () => {
    const data = {
      ...question,
      guid: nanoid(),
    };

    setQuestions((prev) => [...prev, data]);
    handleClear();
  };

  const handleClear = () => {
    setQuestion({
      guid: "",
      question: "",
      requiredAnswer: true,
      isNew: true,
    });
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (questions?.length === 0) {
      toast.remove();
      if (jobDetails && jobDetails?.isAutomated) {
        handleIsAutomated(false);
      }
    }
  }, [questions, jobDetails]);

  const handleRequiredAnswerChange = (value, guid) => {
    setQuestions((prev) =>
      prev.map((q) => {
        if (q.guid === guid) {
          return {
            ...q,
            requiredAnswer: value === "1" ? true : false,
          };
        } else {
          return q;
        }
      })
    );
  };

  const handleDeleteClick = (question) => {
    setQuestionToDelete(question);
    setShowModal(!showModal);
  };

  const handleSave = async (saveOnly) => {
    try {
      const formatedQuestions = questions.map((q) => {
        if (q.isNew) {
          return {
            question: q.question,
            requiredAnswer: q.requiredAnswer,
          };
        } else {
          return q;
        }
      });

      const responsePromise = addQuestions({
        guid: jobGuid,
        questions: formatedQuestions,
      }).unwrap();

      toast.promise(responsePromise, {
        success: "Questions added successfully...",
        loading: "Loading...",
        error: "Something went wrong...!",
      });

      await responsePromise;
      if (!saveOnly) {
        navigate(
          `/my-actions/new-job-posting/${
            jobDetails?.isAutomated ? "automation" : "compensation"
          }/${jobGuid}`
        );
      }
      return true;
    } catch (error) {
      toast.error(error?.data?.Message);
      return false;
    }
  };

  const handleDelete = async () => {
    try {
      if (questionToDelete?.isNew) {
        const filtered = questions.filter(
          (p) => p.guid !== questionToDelete.guid
        );
        setQuestions(filtered);
      } else {
        const responsePromise = deleteQuestion(questionToDelete.guid).unwrap();

        toast.promise(responsePromise, {
          success: "Questions deleted successfully...",
          loading: "Deleting...",
          error: "Something went wrong...!",
        });
      }
      setShowModal(!showModal);
    } catch (error) {
      toast.error(error?.data?.Message);
    }
  };

  const handleIsAutomated = async (isAutomated) => {
    try {
      if (questions.length === 0 && isAutomated) {
        toast.error("At least one question is required to automate...!");
        return;
      }
      const isSaved = handleSave(true);
      if (isSaved) {
        const responsePromise = updateJob({
          guid: jobGuid,
          data: {
            isAutomated,
            jobStatusId: JobStatuses.NOT_COMPLETED,
          },
        }).unwrap();

        toast.promise(responsePromise, {
          success: `Automation ${
            isAutomated ? "Enabled" : "Disabled"
          } Successfully`,
          loading: "Loading...",
          error: "Something went wrong...!",
        });
        await responsePromise;
      }
    } catch (error) {
      toast.error(error?.data?.Message || "Something went wrong");
    }
  };

  return (
    <div className="application-questions">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />

      {(jobDataIsLoading || jobDataIsFetching) && <LoadingSpinner />}

      <div className="row gap-2">
        <div className="col-12 col-md">
          <div className="questions-container">
            <div className="d-flex justify-content-end align-items-center mb-2 ">
              <button
                className={`btn ${
                  isAddNewQuestionVisible ? "back-btn" : "continue-btn"
                }`}
                onClick={() =>
                  setIsAddNewQuestionVisible(!isAddNewQuestionVisible)
                }
              >
                {!isAddNewQuestionVisible ? "Add New Question" : "Cancel"}
              </button>
            </div>
            {isAddNewQuestionVisible && (
              <div className="row align-items-center gap-1 mb-3">
                <div className="col-12">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      aria-label="Enter a question"
                      placeholder="Enter a question"
                      value={question.question}
                      onChange={(e) =>
                        setQuestion((prev) => ({
                          ...prev,
                          question: e.target.value,
                        }))
                      }
                    />
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={handleAddQuestion}
                    >
                      Add
                    </button>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={handleClear}
                    >
                      Clear
                    </button>
                  </div>
                  <div className="d-flex my-3 justify-content-end">
                    <label
                      className="form-check-label me-3 fw-bold"
                      htmlFor="isQuestionRequired"
                    >
                      Expected Answer
                    </label>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="isQuestionRequired"
                        checked={question.requiredAnswer}
                        onChange={(e) =>
                          setQuestion((prev) => ({
                            ...prev,
                            requiredAnswer: e.target.checked,
                          }))
                        }
                      />
                      <span
                        className={`px-2 py-1 rounded text-white inline-block ${
                          question.requiredAnswer ? "bg-primary" : "bg-danger"
                        }`}
                      >
                        {question.requiredAnswer ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <table className="table">
              <thead>
                <tr>
                  <th className="first-column">Application Questions</th>
                  <th className="second-column">Required Answers</th>
                  <th className="check-column">Options</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question, index) => (
                  <tr key={question.guid}>
                    <td>
                      {++index}. {question.question}
                    </td>
                    <td className="second-column">
                      <select
                        className="form-select"
                        value={question?.requiredAnswer ? "1" : "2"}
                        onChange={(e) =>
                          handleRequiredAnswerChange(
                            e.target.value,
                            question.guid
                          )
                        }
                      >
                        <option value="1">Yes</option>
                        <option value="2">No</option>
                      </select>
                    </td>
                    <td className="check-column">
                      <button
                        className="btn"
                        onClick={() => handleDeleteClick(question)}
                      >
                        <img
                          src={deleteIcon}
                          alt="delete"
                          width={20}
                          height={20}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-auto">
          <div className="d-flex my-3 justify-content-start">
            <label
              className="form-check-label me-3 fw-bold"
              htmlFor="isQuestionRequired"
            >
              Automate
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="isQuestionRequired"
                checked={jobDetails?.isAutomated}
                onChange={(e) => handleIsAutomated(e.target.checked)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4 pe-0">
        <div className="col ps-0">
          <button
            className="btn back-btn"
            onClick={() =>
              navigate(`/my-actions/new-job-posting/interview-plan/${jobGuid}`)
            }
          >
            Back
          </button>
        </div>
        <div className="col-auto pe-0">
          <button
            className="btn back-btn me-2"
            onClick={() => handleSave(true)}
          >
            Save
          </button>
          <button
            className="btn continue-btn"
            onClick={() => handleSave(false)}
          >
            Save & continue
          </button>
        </div>
      </div>
      <DeleteConfirmationModal
        type={"Question"}
        showModal={showModal}
        closeModal={setShowModal}
        confirmDelete={handleDelete}
      />
      <AddStageModal
        title={"Field"}
        showModal={addShowModal}
        closeModal={setAddShowModal}
        confirmSubmit={(value) => handleAddNewStage(value)}
      />
    </div>
  );
}
