import React, { Fragment, useEffect, useState } from "react";
import "./Sidebar.css";
import {
  Link,
  NavLink,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { authURL } from "../../utils/variables";
import {
  selectCurrentPermissions,
  selectCurrentUser,
  logOut,
} from "../../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { routes } from "../../utils/routes";
import logoutIcon from "../../assets/icons/logout.svg";
import logo from "../../assets/images/logo.png";
import arrowUp from "../../assets/icons/arrow-up.svg";
import arrowDown from "../../assets/icons/arrow-down.svg";
import collapseIcon from "../../assets/icons/collapseSidebar.svg";
import expandIcon from "../../assets/icons/expandSidebar.svg";

const Sidebar = ({ toggleSidebar, isCollapsed }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const permissions = useSelector(selectCurrentPermissions);
  const location = useLocation();
  const navigate = useNavigate();

  const [isActivityExpanded, setIsActivityExpanded] = useState(true);
  const [isAdminExpanded, setIsAdminExpanded] = useState(true);

  const handleLogout = () => {
    window.history.replaceState(null, null, window.location.pathname);
    dispatch(logOut());
  };

  useEffect(() => {
    if (!user) {
      handleLogout();
    }
  }, [user]);

  return (
    <div className="custom-card sidebar-container">
      <div>
        <h5
          className="nav-brand text-center"
          role="button"
          onClick={() => navigate("/")}
        >
          <img src={logo} alt="logo" />
        </h5>
        {routes.map((route) => (
          <Fragment key={route.path}>
            <div key={route.path}>
              <div className="list-group list-group-flush scrollarea menu-link">
                <NavLink
                  to={`${route.path}`}
                  className="sub-link list-group-item"
                  title={route.name}
                >
                  <img
                    src={
                      location.pathname === `/${route.path}`
                        ? route.iconActive
                        : route.icon
                    }
                    alt={route.name}
                    width={19}
                    height={19}
                  />
                  <span>{route.name}</span>
                </NavLink>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
      <div>
        <div
          className="collapase-container"
          role="button"
          onClick={toggleSidebar}
          title="Collapse Menu"
        >
          <img
            src={isCollapsed ? collapseIcon : expandIcon}
            width={26}
            alt=""
          />
        </div>
        <div className="avatar-dropdown-container">
          <div className="btn-group ">
            <Link
              to={`${authURL}/#/user-management/user-details/${user?.firstName} ${user?.lastName}/${user?.guid}`}
              target="_blank"
            >
              <div className="avatar-container">
                <img
                  src={`${authURL}/v1/companies/users/${user?.guid}/profile-picture`}
                  alt="Avatar"
                  className="avatar-img"
                />
              </div>
            </Link>
            <div className="user-details-container">
              <Link
                to={`${authURL}/#/user-management/user-details/${user?.firstName} ${user?.lastName}/${user?.guid}`}
                target="_blank"
              >
                <p
                  className="fw-bold m-0 user-name"
                  style={{ color: "#cdcdcd" }}
                >
                  {user?.firstName} {user?.lastName}
                </p>
              </Link>
              {/* <span className="role-text">{user?.roles[0]?.description}</span> */}
              <button
                type="button"
                className="btn btn-user"
                onClick={handleLogout}
                title="logout"
              >
                <img src={logoutIcon} width={18} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
