import { createEntityAdapter, nanoid } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const adapter = createEntityAdapter({
  selectId: (item) => item?.guid,
});

const initialState = adapter.getInitialState();

export const talentPoolSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTalentPoolCandidates: builder.query({
      query: (guid) => `/talent-pool/candidates`,
      transformResponse: (response) => {
        const loadedTalentPoolCandidates = response.map((candidateDetails) => {
          const { guid, candidate, feedback, score } = candidateDetails;

          return {
            guid: guid,
            candidateGuid: candidate?.guid,
            name: candidate?.name,
            email: candidate?.email,
            contact: candidate?.mobile ?? "N/A",
            attachment: candidate?.attachment,
            feedback: feedback,
            score,
          };
        });

        return adapter.setAll(initialState, loadedTalentPoolCandidates);
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [
            { type: "TalentPoolCandidates", id: "LIST" },
            { type: "UserNotifications", id: "LIST" },
          ];
        } else {
          return [
            { type: "UserNotifications", id: "LIST" },
            { type: "TalentPoolCandidates", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "TalentPoolCandidates",
              id: id?.guid,
            })),
          ];
        }
      },
    }),
    removeCandidateFromCandidatePool: builder.mutation({
      query: ({ guid }) => ({
        url: `/talent-pool/candidates/${guid}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "TalentPoolCandidates" },
      ],
    }),
  }),
});

export const {
  useGetTalentPoolCandidatesQuery,
  useRemoveCandidateFromCandidatePoolMutation,
} = talentPoolSlice;
