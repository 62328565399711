import { React, useState, useEffect } from "react";
import "./NotificationPanel.css";
import toast, { Toaster } from "react-hot-toast";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import viewNotification from "../../assets/icons/viewNotification.svg";
import userNotifiedIcon from "../../assets/icons/NotifiedIcon.svg";
import {
  useGetCurrentUserNotificationsQuery,
  useNotificationMarkAsReadMutation,
} from "../../features/commonDataSlice/NotificationSlice";
import { format, parseISO } from "date-fns";

const NotificationPanel = ({
  showNotificationModal,
  closeNotificationModel,
  notificationCount,
}) => {
  const [userNotifications, setUserNotifications] = useState([]);

  const dateFormatter = (dateString) => {
    return format(parseISO(dateString), "dd/MM/yyyy hh:mm a");
  };

  const {
    data: currentuserNotifications,
    isError: currentuserNotificationsIsError,
    error: currentuserNotificationsError,
  } = useGetCurrentUserNotificationsQuery();

  const [
    notificationMarkAsRead,
    {
      isLoading: notificationMarkAsReadIsLoading,
      isSuccess: notificationMarkAsReadIsSuccess,
    },
  ] = useNotificationMarkAsReadMutation();

  useEffect(() => {
    if (currentuserNotifications) {
      const data = currentuserNotifications?.ids?.map(
        (id) => currentuserNotifications?.entities[id]
      );
      const sortedData = data.sort(
        (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
      );
      setUserNotifications(sortedData);
    }
    if (currentuserNotificationsIsError) {
      toast.error(currentuserNotificationsError?.data?.Message);
    }
  }, [currentuserNotifications, currentuserNotificationsIsError]);

  useEffect(() => {
    const count =
      userNotifications?.filter((notification) => !notification?.isUserViewed)
        .length || 0;
    notificationCount(count);
  }, [userNotifications, currentuserNotifications]);

  const notificationOnClick = async (notificationGuid) => {
    try {
      await notificationMarkAsRead({
        guid: notificationGuid ? notificationGuid : "",
      }).unwrap();
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 4000,
      });
    }
  };

  return (
    <div>
      <Toaster position="top right" />
      <Modal
        style={{ padding: 0 }}
        isOpen={showNotificationModal}
        className="notification-modal"
        scrollable={true}
      >
        <ModalHeader
          toggle={() => closeNotificationModel(!showNotificationModal)}
        >
          Notifications
        </ModalHeader>

        <ModalBody>
        <div className="notification-dropdown">
          {userNotifications.length > 0 ? (
            userNotifications.map((notification) => (
              <div className="d-flex align-items-center" key={notification.guid}>
                <div
                  className={`${
                    notification?.isUserViewed ? "notified" : "notification-content"
                  }`}
                  onClick={() => {
                    closeNotificationModel(!showNotificationModal);
                    if (notification?.isUserViewed === false) {
                      notificationOnClick(notification?.guid);
                    }
                    window.location.href = `${process.env.REACT_APP_API_URL}${notification?.redirectUrl}`;
                  }}
                >
                  <div className="d-flex">
                    <div className="icon-placeholder">
                      <img
                        src={notification?.isUserViewed ? userNotifiedIcon : viewNotification}
                        width={25}
                        alt=""
                      />
                    </div>
                    <div className="px-2 pb-1">
                      <h6 className={`m-0 ${notification?.isUserViewed ? "notified-title" : ""}`}>
                        {notification?.title}
                      </h6>
                    </div>
                  </div>
                  <div className="notification-container">
                    <p
                      className={`m-0 text-wrap ${
                        notification?.isUserViewed ? "notified-description" : "notification-text"
                      }`}
                    >
                      {notification?.description}
                    </p>
                    <small className="py-1 created-on-details">
                      {dateFormatter(notification?.createdOn)}
                    </small>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h6 className="p-4">No any notifications to show</h6>
          )}
        </div>
      </ModalBody>
      </Modal>
    </div>
  );
};

export default NotificationPanel;
