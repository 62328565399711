import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useEffect, useState } from "react";
import "./EditJobPostModal.css";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

export default function EditJobPostModal({
  closeModal,
  showModal,
  confirmSubmit,
  jobId,
  jobTitle,
  jobDescription,
  requiredSkills,
}) {
  const [skills, setSkills] = useState("");
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setTitle(jobTitle);
    setDescription(jobDescription);
    setSkills(requiredSkills);
  }, [jobTitle, jobDescription, requiredSkills]);

  const toolbarSettings = {
    items: ["Bold", "Italic", "Underline", "CreateLink", "Image"],
    type: "Expand",
  };

  const skillTechs = [
    "C",
    "C++",
    "Java",
    "Python",
    "JavaScript",
    "Ruby",
    "Swift",
    "Kotlin",
    "Go (Golang)",
    "Rust",
    "PHP",
    "Perl",
    "TypeScript",
    "Dart",
    "Scala",
    "R",
    "MATLAB",
    "Lua",
    "Shell scripting languages (Bash, PowerShell, etc.)",
    "Django (Python)",
    "Flask (Python)",
    "Ruby on Rails (Ruby)",
    "Spring Boot (Java)",
    "Express.js (JavaScript)",
    "Angular (JavaScript/TypeScript)",
    "React.js (JavaScript)",
    "Vue.js (JavaScript)",
    "Laravel (PHP)",
    "Symfony (PHP)",
    "Android SDK (Java/Kotlin)",
    "React Native (JavaScript/TypeScript)",
    "Flutter (Dart)",
    "SwiftUI (Swift)",
    "Electron (JavaScript/TypeScript)",
    "Qt (C++)",
    "Tkinter (Python)",
    "WPF (.NET)",
    "Unity (C#)",
    "Unreal Engine (C++)",
    "Godot (GDScript)",
    "TensorFlow (Python)",
    "PyTorch (Python)",
    "scikit-learn (Python)",
    "Keras (Python)",
    "Pandas (Python)",
    "NumPy (Python)",
    "Apache Spark (Java/Scala)",
    "Hadoop (Java)",
    "Node.js (JavaScript)",
    "Flask (Python)",
    "Django (Python)",
    "Ruby on Rails (Ruby)",
    "Spring Boot (Java)",
    "Laravel (PHP)",
    "Express.js (JavaScript)",
    "Bootstrap (HTML/CSS/JavaScript)",
    "Tailwind CSS (CSS)",
    "Sass (CSS)",
    "LESS (CSS)",
    "jQuery (JavaScript)",
    "D3.js (JavaScript)",
    "C",
    "C++",
    "Rust",
    "Assembly Language",
    "Haskell",
    "Erlang",
    "Scala",
    "Clojure",
  ];

  const handleSkillAdd = (text) => {
    const tempArray = [...skills];
    if (tempArray.some((skill) => skill === text)) {
      return;
    }
    tempArray.push(text);
    setSkills(tempArray);
    setValue("");
  };

  const handleSkillRemove = (text) => {
    const tempArray = [...skills];
    setSkills(tempArray.filter((skill) => skill !== text));
  };

  return (
    <Modal isOpen={showModal} className="edit-job" size="lg" centered={true}>
      <ModalHeader className="px-3" toggle={() => closeModal(!showModal)}>
        <span className="header-title">{jobTitle}</span>
      </ModalHeader>
      <ModalBody className="modal-container">
        <div className="row">
          <div className="col-xl-3 col-md-4">
            <p className="head-text">Job Title</p>
          </div>
          <div className="col-xl-7 col-md-8">
            <input
              className="form-control"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-4">
            <p className="head-text">Job Description</p>
          </div>
          <div className="col-xl-7 col-md-8">
            <RichTextEditorComponent
              toolbarSettings={toolbarSettings}
              placeholder="Enter Job Description"
              value={description}
              change={(args) =>
                setDescription(
                  args.value
                    ? args.value.replace(/<p>/g, "").replace(/<\/p>/g, "")
                    : ""
                )
              }
            >
              <Inject
                services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
              />
            </RichTextEditorComponent>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-4">
            <p className="head-text">Required Skills</p>
          </div>
          <div className="col-xl-7 col-md-8">
            <input
              className="form-control"
              placeholder="Type your skills"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            {value && (
              <div className="custom-card">
                {skillTechs
                  .filter((tech) => {
                    const searchTerm = value.toLowerCase();
                    const skillValue = tech.toLowerCase();
                    return searchTerm && skillValue.startsWith(searchTerm);
                  })
                  .map((item) => (
                    <div
                      className="skill-items"
                      onClick={() => handleSkillAdd(item)}
                      style={{ cursor: "pointer" }}
                    >
                      {item}
                    </div>
                  ))}
              </div>
            )}
            <div className="skills-container">
              {skills &&
                !value &&
                skills?.map((skill) => (
                  <div key={skill} className="skill">
                    {skill}
                    <div
                      className="close-icon"
                      onClick={() => handleSkillRemove(skill)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn continue-btn"
          onClick={() => confirmSubmit(jobId, title, description, skills)}
        >
          Save & Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
}
