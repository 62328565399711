import { apiSlice } from "../../app/api/apiSlice";

export const candidatesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCandidatesByJob: builder.query({
      query: ({
        jobInterviewPipelineStageGuid,
        interviewDate,
        query,
        jobGuid,
        page,
        pageSize,
      }) => {
        if (jobGuid) {
          return `/jobs/${jobGuid}/candidates?page=${page}&pageSize=${pageSize}&jobInterviewPipelineStageGuid=${jobInterviewPipelineStageGuid}&interviewDate=${interviewDate}&query=${query}`;
        }
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [
            { type: "Candidates", id: "LIST" },
            { type: "UserNotifications", id: "LIST" },
          ];
        } else {
          return [
            { type: "UserNotifications", id: "LIST" },
            { type: "Candidates", id: "LIST" },
            ...result.items.map((item) => ({
              type: "Candidates",
              id: item.guid,
            })),
          ];
        }
      },
    }),

    getJobInterviewPipelineStages: builder.query({
      query: (jobGuid) => {
        if (jobGuid) {
          return `/jobs/${jobGuid}/interview-pipeline-stages`;
        }
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [
            { type: "JobInterviewPipelineStages", id: "LIST" },
            { type: "UserNotifications", id: "LIST" },
          ];
        } else {
          return [
            { type: "UserNotifications", id: "LIST" },
            { type: "JobInterviewPipelineStages", id: "LIST" },
            ...result.map((item) => ({
              type: "JobInterviewPipelineStages",
              id: item.guid,
            })),
          ];
        }
      },
    }),

    getCandidateAppliedJob: builder.query({
      query: (appliedJobGuid) => `/applied-jobs/${appliedJobGuid}`,
      providesTags: (result, error, arg) => [
        { type: "CandidateAppliedJob", id: arg },
        { type: "CandidateAppliedJob", id: "LIST" },
      ],
    }),

    getAppliedJobInterviewPipelineStatuses: builder.query({
      query: () => `/applied-job-interview-pipeline-stages/statuses`,
      providesTags: (result, error, arg) => [
        { type: "AppliedJobInterviewPipelineStatuses", id: "List" },
      ],
    }),

    getAppliedJobInterviewQuestions: builder.query({
      query: (stageGuid) => {
        if (stageGuid) {
          return `/applied-jobs/interview-pipeline-stages/${stageGuid}/questions`;
        }
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "AppliedJobInterviewQuestions", id: "LIST" }];
        } else {
          return [
            { type: "AppliedJobInterviewQuestions", id: "LIST" },
            ...result.map((item) => ({
              type: "AppliedJobInterviewQuestions",
              id: item.guid,
            })),
          ];
        }
      },
    }),

    getAttachment: builder.query({
      query: (guid) => `/attachments/${guid}`,
      providesTags: (result, error, arg) => [{ type: "Attachments", id: arg }],
    }),

    addCandidateToTalentPool: builder.mutation({
      query: (data) => ({
        url: `/talent-pool/candidates`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "TalentPoolCandidates", id: "LIST" },
      ],
    }),

    addFeedbackToAppliedJobInterviewStage: builder.mutation({
      query: ({ appliedJobGuid, guid, data }) => ({
        url: `/applied-jobs/interview-pipeline-stages/${guid}/feedback`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CandidateAppliedJob", id: arg.appliedJobGuid },
      ],
    }),

    addCommentToAppliedJobInterviewStage: builder.mutation({
      query: ({ appliedJobGuid, guid, data }) => ({
        url: `/applied-jobs/interview-pipeline-stages/${guid}/comments`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CandidateAppliedJob", id: arg.appliedJobGuid },
      ],
    }),

    updateCommentToAppliedJobInterviewStage: builder.mutation({
      query: ({ appliedJobGuid, guid, data }) => ({
        url: `/applied-jobs/interview-pipeline-stages/comments/${guid}`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CandidateAppliedJob", id: arg.appliedJobGuid },
      ],
    }),

    updateAppliedJobInterviewPipelineStatus: builder.mutation({
      query: ({ appliedJobGuid, guid, data }) => ({
        url: `/applied-jobs/interview-pipeline-stages/${guid}/status`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CandidateAppliedJob", id: arg.appliedJobGuid },
      ],
    }),

    updateAppliedJobInterviewQuestions: builder.mutation({
      query: ({ questionGuid, data }) => ({
        url: `/applied-jobs/interview-pipeline-stages/questions/feedback`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AppliedJobInterviewQuestions", id: arg.questionGuid },
      ],
    }),

    scheduleMeetingforAppliedJobInterviewPipelineStage: builder.mutation({
      query: ({ appliedJobGuid, guid, data }) => ({
        url: `/applied-jobs/interview-pipeline-stages/${guid}:schedule`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CandidateAppliedJob", id: arg.appliedJobGuid },
      ],
    }),
    updateAppliedJobStatus: builder.mutation({
      query: ({ guid, statusGuid }) => ({
        url: `/applied-jobs/${guid}/status`,
        method: "PUT",
        body: {
          statusGuid,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Candidates", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetCandidatesByJobQuery,
  useGetJobInterviewPipelineStagesQuery,
  useGetCandidateAppliedJobQuery,
  useGetAppliedJobInterviewPipelineStatusesQuery,
  useGetAppliedJobInterviewQuestionsQuery,
  useAddCandidateToTalentPoolMutation,
  useAddFeedbackToAppliedJobInterviewStageMutation,
  useAddCommentToAppliedJobInterviewStageMutation,
  useUpdateCommentToAppliedJobInterviewStageMutation,
  useUpdateAppliedJobInterviewPipelineStatusMutation,
  useUpdateAppliedJobInterviewQuestionsMutation,
  useScheduleMeetingforAppliedJobInterviewPipelineStageMutation,
  useUpdateAppliedJobStatusMutation,
  useGetAttachmentQuery,
} = candidatesSlice;
