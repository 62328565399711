import { createEntityAdapter, nanoid } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

export const companySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompany: builder.query({
      query: () => `/companies`,
      providesTags: (result, error) => {
        if (error) {
          return [{ type: "Company", id: "ERROR" }];
        } else {
          return [{ type: "Company", id: "CompanyDetails" }];
        }
      },
    }),
    updateCompany: builder.mutation({
      query: (data) => ({
        url: `/companies`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error) => {
        if (error) {
          return [{ type: "Company", id: "ERROR" }];
        }
        return [{ type: "Company", id: "CompanyDetails" }];
      },
    }),
  }),
});

export const { useGetCompanyQuery, useUpdateCompanyMutation } = companySlice;
