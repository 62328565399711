import React, { useEffect, useState } from "react";
import PdfIcon from "../../assets/icons/Docs_icons.svg";
import EmailIcon from "../../assets/icons/email-icon.svg";
import "./CandidateViewSidebar.css";
import PreliminaryQuestionsModal from "../PreliminaryQuestionsModal/PreliminaryQuestionsModal";
import detailsIcon from "../../assets/icons/details.svg";
import detailsActiveIcon from "../../assets/icons/details - active.svg";
import arrowDown from "../../assets/icons/arrow-down.svg";
import arrowUp from "../../assets/icons/arrow-up.svg";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../features/auth/authSlice";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import SmallLoadingSpinner from "../common/SmallLoadingSpinner/SmallLoadingSpinner";
import { attachmentTypes } from "../../enums/attachmentTypes";

const CandidateViewSidebar = ({ active, setActive, data }) => {
  const token = useSelector(selectCurrentToken);

  const [showModal, setShowModal] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [attachmentLinks, setAttachmentLinks] = useState([]);
  const [attachmentGuid, setAttachmentGuid] = useState("");

  useEffect(() => {
    if (data) {
      let candidateAttachments = data?.appliedJobAttachments?.map((item) => ({
        ...item?.candidateAttachment?.attachment,
        type: item?.candidateAttachment?.type,
      }));
      if (
        candidateAttachments?.length &&
        candidateAttachments[0]?.type?.id === attachmentTypes.COVER_LETTER
      ) {
        candidateAttachments.reverse();
      }
      setAttachments(candidateAttachments);
    }
  }, [data]);

  const handleAttachmentView = (attachment) => {
    const file = attachmentLinks?.filter((at) => at.guid === attachment.guid);

    if (file.length !== 0) {
      window.open(file[0].url);
    } else {
      setAttachmentGuid(attachment.guid);
      setIsFileDownloading(true);

      if (token) {
        const axiosConfig = {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        axios
          .get(
            `${
              window.location.hostname === "localhost"
                ? process.env.REACT_APP_API_URL
                : process.env.PUBLIC_URL
            }/v1/attachments/${attachment.guid}`,
            axiosConfig
          )
          .then((response) => {
            setIsFileDownloading(false);

            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: response.headers["content-type"],
              })
            );
            setAttachmentLinks([
              ...attachmentLinks,
              { guid: attachment.guid, url: url },
            ]);
            window.open(url);
          })
          .catch((error) => toast.error(error.message));
      }
    }
  };

  return (
    <div className="candidate-view-sidebar p-3">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="user-image-container d-flex align-items-center">
        <div 
        className="user-image">
          <img
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              cursor: "pointer",
              backgroundPosition:"center",
              backgroundSize:"cover"
            }}
            src={`${
              window.location.hostname === "localhost"
                ? process.env.REACT_APP_API_URL
                : process.env.PUBLIC_URL
            }/v1/candidates/${data?.candidate?.guid}/profile-picture`}
          />
        </div>
      </div>
      <div className="candidate-details-container">
        <h5 className="m-0">{data.candidate?.fullName}</h5>
        <p className="text-secondary m-0 pb-1">
          {data.job?.name}, {data.candidate?.currentCompany?.location}
        </p>
        <div className="row text-break email-section">
          <div className="py-1">
            <img src={EmailIcon} width={18} alt="" />
            <span className="m-0 fw-normal px-1">{data.candidate?.email}</span>
          </div>
          {attachments?.map((attachment) => (
            <div key={attachment.guid} className="row mb-1">
              <div className="col-6">
                <label>{attachment?.type?.name}</label>
              </div>
              <div className="col-6 d-flex ps-0 position-relative">
                <img src={PdfIcon} width={18} height={18} alt="" />
                <Link
                  onClick={() => handleAttachmentView(attachment)}
                  className="attachment ms-1"
                >
                  {attachment?.fileName}
                  {attachmentGuid === attachment?.guid && isFileDownloading && (
                    <SmallLoadingSpinner />
                  )}
                </Link>
              </div>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-between align-items-baseline skills-container">
          <div
            className={`col d-flex gap-2 mt-2 flex-wrap overflow-hidden skills-box ${
              showAll ? "show" : "hide"
            }`}
          >
            {data.candidate?.skills?.map((skill, index) => (
              <div
                key={skill.guid}
                className={`skill-needed ${
                  index < 3 || showAll ? "" : "hidden"
                }`}
              >
                {skill.name} <div className="close-icon" onClick={() => {}} />
              </div>
            ))}
          </div>
          <div className="col-auto">
            <img
              src={showAll ? arrowUp : arrowDown}
              alt="dropdown"
              onClick={() => setShowAll(!showAll)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="mt-3 preliminary-question-container">
          <button
            className="btn preliminary-question w-100"
            onClick={() => setShowModal(!showModal)}
            disabled={data?.appliedJobApplicationQuestions?.length === 0}
          >
            Preliminary questions
            <span className="p-1 border rounded">
              {data?.appliedJobApplicationQuestions?.length || 0}
            </span>
          </button>
        </div>
        <div className="d-flex flex-column candidate-btns">
          <button
            className={`btn ${active === "ScheduleInterview" ? "active" : ""}`}
            onClick={() => setActive("ScheduleInterview")}
          >
            Schedule Interview
            <img
              src={
                active === "ScheduleInterview" ? detailsActiveIcon : detailsIcon
              }
              alt="details"
            />
          </button>
          <button
            className={`btn ${active === "InterviewKit" ? "active" : ""}`}
            onClick={() => setActive("InterviewKit")}
          >
            Interview Kit
            <img
              src={active === "InterviewKit" ? detailsActiveIcon : detailsIcon}
              alt="details"
            />
          </button>
          <button
            className={`btn ${active === "PerformanceReview" ? "active" : ""}`}
            onClick={() => setActive("PerformanceReview")}
          >
            Performance Review
            <img
              src={
                active === "PerformanceReview" ? detailsActiveIcon : detailsIcon
              }
              alt="details"
            />
          </button>
        </div>
      </div>
      <PreliminaryQuestionsModal
        showModal={showModal}
        closeModal={setShowModal}
        data={data?.appliedJobApplicationQuestions}
      />
    </div>
  );
};

export default CandidateViewSidebar;
