import React, { useState } from "react";
import "./AddToTalentPoolModal.css";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";

export default function AddToTalentPoolModal({
  showModal,
  closeModal,
  handleSubmit,
}) {
  const [rangeSliderValue, setRangeSliderValue] = useState(0);
  const [feedback, setFeedback] = useState("");

  const handleSliderChange = (args) => {
    setRangeSliderValue(args?.value);
  };

  return (
    <div>
      <Modal
        isOpen={showModal}
        className="add-to-talent-pool-modal"
        size="lg"
        centered={true}
      >
        <ModalHeader className="px-3" toggle={() => closeModal(!showModal)}>
          Add to Talent Pool
        </ModalHeader>
        <ModalBody>
          <div className="text-secondary fw-bold w-100">
            <div>
              <h6>Score</h6>
              <span>{rangeSliderValue + "%"}</span>
            </div>
            <SliderComponent
              id="slider"
              type="MinRange"
              value={rangeSliderValue}
              width="100%"
              tooltip="After"
              immediateRender={true}
              enableAnimation={true}
              change={handleSliderChange}
            ></SliderComponent>
          </div>
          <div>
            <h6 className="text-secondary">Feedback</h6>
            <div>
              <textarea
                className="feedback-text-area"
                name="feedback"
                id="feedback"
                cols={100}
                rows={5}
                placeholder="Write something..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              ></textarea>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn continue-btn"
            onClick={() =>
              handleSubmit({
                feedback: feedback,
                score: rangeSliderValue,
              })
            }
          >
            Add to Talent Pool
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
