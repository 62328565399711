import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { registerLicense } from "@syncfusion/ej2-base";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import App from "./App";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_Google_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
      </HashRouter>
    </Provider>
  </React.StrictMode>
);
