import React, { useEffect, useState } from "react";
import "./PreliminaryQuestionsModal.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export default function PreliminaryQuestionsModal({
  showModal,
  closeModal,
  data,
}) {
  const [questionData, setQuestionData] = useState([]);

  useEffect(() => {
    if (data) {
      const questions = data.map((item) => ({
        ...item?.question,
        providedAnswer: item?.answer,
      }));
      setQuestionData(questions);
    }
  }, [data]);

  return (
    <div className="preliminary-modal">
      <Modal
        isOpen={showModal}
        className="preliminary-question-modal"
        size="lg"
        centered={true}
        backdropClassName="modal-backdrop-custom"
      >
        <ModalHeader
          className="px-3"
          toggle={() => closeModal(!showModal)}
        ></ModalHeader>
        <ModalBody className="modal-container">
          <div className="row">
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th className="first-column">Application Questions</th>
                    <th className="second-column">Required Answer</th>
                    <th className="check-column">Provided Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {questionData?.map((question, index) => (
                    <tr key={question?.guid}>
                      <td>
                        {index + 1}. {question?.question}
                      </td>
                      <td className="second-column">
                        {question?.requiredAnswer ? "Yes" : "No"}
                      </td>
                      <td className="check-column">
                        {question?.providedAnswer ? "Yes" : "No"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
