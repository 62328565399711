import { apiSlice } from "../../app/api/apiSlice";

export const myActionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query({
      query: ({ statusGuid, page, pageSize }) =>
        `/jobs?page=${page}&pageSize=${pageSize}&statusGuid=${statusGuid}`,
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "Jobs", id: "LIST" }, { type: "UserNotifications", id: "LIST" }];
        } else {
          return [
            { type: "UserNotifications", id: "LIST" },
            { type: "Jobs", id: "LIST" },
            ...result.items.map((item) => ({
              type: "Jobs",
              id: item.guid,
            })),
          ];
        }
      },
    }),
    getMyJobs: builder.query({
      query: ({ statusGuid, page, pageSize }) =>
        `/jobs/my?page=${page}&pageSize=${pageSize}&statusGuid=${statusGuid}`,
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "MyJobs", id: "LIST" }, { type: "UserNotifications", id: "LIST" }];
        } else {
          return [
            { type: "UserNotifications", id: "LIST" },
            { type: "MyJobs", id: "LIST" },
            ...result.items.map((item) => ({
              type: "MyJobs",
              id: item.guid,
            })),
          ];
        }
      },
    }),
    getJobByGuid: builder.query({
      query: (guid) => `/jobs/${guid}`,
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "Job", id: arg, invalidating: true }, { type: "UserNotifications", id: "LIST" }];
        } else {
          return [{ type: "Job", id: arg }, { type: "UserNotifications", id: "LIST" }];
        }
      },
    }),
    getMyJobsInsights: builder.query({
      query: () => `/insights/jobs/my`,
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "MyJobsInsights", id: "LIST" }, { type: "UserNotifications", id: "LIST" }];
        } else {
          return [{ type: "MyJobsInsights", id: "LIST" }, { type: "UserNotifications", id: "LIST" }];
        }
      },
    }),
  }),
});

export const {
  useGetJobsQuery,
  useGetMyJobsQuery,
  useGetJobByGuidQuery,
  useGetMyJobsInsightsQuery,
} = myActionsSlice;
