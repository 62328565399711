export const JobStatuses = {
  OPEN: 1,
  FILLED: 2,
  NOT_POSTED: 3,
  NOT_COMPLETED: 4,
};

export const JobStatusesWithGuid = {
  OPEN: "3582db28-91bb-488a-b92f-bda467cf92b4",
  FILLED: "b3963bc2-2476-49dc-b764-767abcd122ee",
  NOT_POSTED: "67514223-59ba-41d7-9fa5-449b63760fb5",
  NOT_COMPLETED: "f8ad2e83-4fb1-4d91-869b-11374bcbccf4",
};
