import { apiSlice } from "../../app/api/apiSlice";

export const jobPostingSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJobsInsights: builder.query({
      query: () => `/insights/jobs`,
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "JobsInsights", id: "LIST" }, { type: "UserNotifications", id: "LIST" }];
        } else {
          return [
            { type: "UserNotifications", id: "LIST" },
            { type: "JobsInsights", id: "LIST" },
          ];
        }
      },
    }),
    deleteJob: builder.mutation({
      query: ({ guid }) => ({
        url: `/jobs/${guid}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Jobs" },
        { type: "MyJobs" },
        { type: "JobsInsights" },
      ],
    }),
  }),
});

export const { useGetJobsInsightsQuery, useDeleteJobMutation} = jobPostingSlice;
