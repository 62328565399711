import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./AddStageModal.css";

const AddStageModal = ({ title, closeModal, showModal, confirmSubmit }) => {
  const [value, setValue] = useState("");

  const handleOnSubmit = () => {
    if (!value) {
      return;
    }
    confirmSubmit(value);
    setValue("");
  };

  return (
    <Modal style={{ padding: 0 }} isOpen={showModal} centered={true}>
      <ModalHeader
        className="tender-question-modal"
        toggle={() => closeModal(!showModal)}
      >
        <span className="add-question-modal-title">Add {title}</span>
      </ModalHeader>
      <ModalBody className="tender-question-modal text-secondary">
        <textarea
          id="tender-question-input"
          className="form-control p-2 mt-3 "
          style={{
            resize: "none",
            fontSize: "14px",
            zIndex: 5,
            padding: "5px",
          }}
          rows={7}
          cols={7}
          placeholder={`Enter ${title} name...`}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        ></textarea>
      </ModalBody>
      <ModalFooter className="tender-question-modal d-flex justify-content-between">
        <Button
          color="secondary"
          className="back-btn"
          onClick={() => closeModal(!showModal)}
          style={{ width: "auto" }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="continue-btn add-btn"
          onClick={handleOnSubmit}
          style={{ width: "80px", backgroundColor: "#3b5998" }}
        >
          Add
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddStageModal;
