import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useEffect, useState } from "react";
import "./AddNewUserModal.css";
import toast, { Toaster } from "react-hot-toast";
import {
  useGetUsersQuery,
  useGetTeamRolesQuery,
} from "../../features/commonDataSlice/usersSlice";
import closeIcon from "../../assets/icons/close.svg";
import addIcon from "../../assets/icons/add.svg";

export default function AddNewUserModal({
  closeModal,
  showModal,
  confirmSubmit,
}) {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userGuid, setUserGuid] = useState("");
  const [roleGuid, setRoleGuid] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);

  const {
    data: usersData,
    isLoading: usersDataIsLoading,
    isFetching: usersDataIsFetching,
    isSuccess: usersDataIsSuccess,
    isError: usersDataIsError,
    error: usersDataError,
  } = useGetUsersQuery();

  const {
    data: teamRolesData,
    isLoading: teamRolesDataIsLoading,
    isFetching: teamRolesDataIsFetching,
    isSuccess: teamRolesDataIsSuccess,
    isError: teamRolesDataIsError,
    error: teamRolesDataError,
  } = useGetTeamRolesQuery();

  useEffect(() => {
    if (usersData) {
      const data = usersData.ids?.map((id) => usersData.entities[id]);
      setUsers(data);
    }
    if (usersDataIsError) {
      toast.error(usersDataError?.data?.Message);
    }
  }, [usersData, usersDataIsError]);

  useEffect(() => {
    if (teamRolesData) {
      const data = teamRolesData.ids.map((id) => teamRolesData.entities[id]);
      setRoles(data);
    }
    if (teamRolesDataIsError) {
      toast.error(usersDataError?.data?.Message);
    }
  }, [teamRolesData, teamRolesDataIsError]);

  const handleAdd = () => {
    if (roleGuid && userGuid) {
      const user = users.find((u) => u.guid === userGuid);
      const role = roles.find((r) => r.guid === roleGuid);

      if (!user || !role) return;

      const exists = selectedMembers?.some(
        (member) =>
          member.user.guid === userGuid && member.role.guid === roleGuid
      );

      if (!exists) {
        setSelectedMembers((prev) => [...prev, { user, role }]);
      } else {
        toast.error("Member already exists");
      }
    } else {
      toast.error("Please Select a User and a Role");
    }
  };

  const removeSelectedMember = (guid) => {
    setSelectedMembers((prev) => prev.filter((u) => u.user.guid !== guid));
  };

  const handleSave = () => {
    if (selectedMembers?.length === 0) {
      toast.error("No members selected ");
      return;
    }

    const data = {
      members: selectedMembers.map((member) => {
        const { role, user } = member;
        return {
          roleGuid: role.guid,
          userGuid: user.guid,
        };
      }),
    };
    confirmSubmit(data);
    setRoleGuid("");
    setUserGuid("");
    setSelectedMembers([]);
    closeModal();
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        className="add-new-user"
        size="md"
        centered={true}
        fade={true}
      >
        <ModalHeader className="px-3" toggle={() => closeModal(!showModal)}>
          <span className="header-title">Add Team Members</span>
        </ModalHeader>
        <ModalBody className="modal-container">
          <div className="row">
            <div className="col-12 col-md">
              <label htmlFor="user">User</label>
              <select
                id="user"
                className="form-select"
                onChange={(e) => setUserGuid(e.target.value)}
                value={userGuid}
              >
                <option value={""}>Choose a User</option>
                {users?.map((user) => (
                  <option key={user.guid} value={user.guid}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md">
              <label htmlFor="role">Role</label>
              <select
                id="role"
                className="form-select"
                onChange={(e) => setRoleGuid(e.target.value)}
                value={roleGuid}
              >
                <option value={""}>Choose a role</option>
                {roles.map((role) => (
                  <option key={role.guid} value={role.guid}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-auto align-self-end">
              <Button
                className="btn add-btn"
                onClick={handleAdd}
                title="Add Members"
              >
                <img src={addIcon} width={24} />
              </Button>
            </div>
          </div>
          <div>
            {selectedMembers?.length > 0 && <h6>Selected Members</h6>}

            <div className="row gap-1">
              {selectedMembers?.map((member, index) => (
                <div key={index} className="col-auto align-self-stretch">
                  <div className="selected-member">
                    {`${member?.user?.name} - ${member?.role?.name}`}
                    <button
                      className="btn btn-sm p-0 ms-1"
                      onClick={() => removeSelectedMember(member?.user?.guid)}
                    >
                      <img src={closeIcon} alt="" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="btn continue-btn" onClick={handleSave}>
            Save & Continue
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
