export const appliedJobInterviewPiplineStageStatus = {
  COMPLETED: "630e9d39-fbe0-4647-8d2d-ae06ad9fd455",

  INCOMPLETED: "d57925b9-df1b-4ce1-9a77-88a1046ec6d4",

  SCHEDULED: "9cf39905-4324-4e21-875d-b6b590e2648b",

  PENDING: "8eb56d7d-6276-4a97-9bc1-ef6519b42637",

  REJECTED: "deb5a600-9cd9-482d-8e9b-81f1d1997d47",
};
