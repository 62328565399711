import { createEntityAdapter, nanoid } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const adapter = createEntityAdapter({
  selectId: (user) => user?.guid,
});
const roleAdapter = createEntityAdapter({
  selectId: (adapter) => adapter?.guid,
});

const initialState = adapter.getInitialState();
const roleInitialState = roleAdapter.getInitialState();

export const staticDataSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => `/users`,
      transformResponse: (response) => {
        const loadedData = response;
        return adapter.setAll(initialState, loadedData);
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "Users", id: "LIST" }];
        } else {
          return [
            { type: "Users", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "Users",
              id: id?.guid,
            })),
          ];
        }
      },
    }),
    getTeamRoles: builder.query({
      query: () => `/team-roles`,
      transformResponse: (response) => {
        const loadedData = response;
        return roleAdapter.setAll(roleInitialState, loadedData);
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "TeamRoles", id: "LIST" }];
        } else {
          return [
            { type: "TeamRoles", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "TeamRoles",
              id: id.guid,
            })),
          ];
        }
      },
    }),
    createTeamForJob: builder.mutation({
      query: ({ guid, data }) => ({
        url: `/jobs/${guid}/teams`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Job" },
        { type: "Jobs" },
        { type: "Teams" },
      ],
    }),
    updateTeam: builder.mutation({
      query: ({ guid, data }) => ({
        url: `/teams/${guid}`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Job" },
        { type: "Jobs" },
        { type: "Teams" },
      ],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetTeamRolesQuery,
  useCreateTeamForJobMutation,
  useUpdateTeamMutation,
} = staticDataSlice;
