import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import { gapi } from "google-api-javascript-client";
import axios from "axios";
import { nanoid } from "@reduxjs/toolkit";
import "./ArrangeMeetingModal.css";

const ArrangeMeetingModal = ({ showModal, closeModal }) => {
  const [meetingTitle, setMeetingTitle] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [collaborators, setCollaborators] = useState([]);
  const [description, setDescription] = useState("");
  const [eventDetails, setEventDetails] = useState("");

  const accessToken = localStorage.getItem("GoogleAccessToken");

  useEffect(() => {
    gapi.load("client", () => {
      gapi.client
        .init({
          clientId: process.env.REACT_APP_Google_CLIENT_ID,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scopes:
            "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly",
        })
        .then(
          () => {
            console.log("Google API Client initialized successfully");
          },
          (error) => {
            console.error("Error initializing Google API Client:", error);
          }
        );
    });
  }, []);

  async function createGoogleMeetEvent() {
    const event = {
      summary: meetingTitle,
      description: description,
      start: {
        dateTime: startDate,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: endDate,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      attendees: collaborators,
      conferenceData: {
        createRequest: {
          requestId: nanoid(), // Generate a unique request ID
          conferenceSolutionKey: {
            type: "hangoutsMeet",
          },
        },
      },
      reminders: {
        useDefault: false,
        overrides: [
          { method: "email", minutes: 24 * 60 },
          { method: "popup", minutes: 10 },
        ],
      },
    };

    await fetch(
      "https://www.googleapis.com/calendar/v3/calendars/primary/events?conferenceDataVersion=1&sendUpdates=all",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ` + accessToken,
        },
        body: JSON.stringify(event),
      }
    )
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setEventDetails(data);
      });
  }

  async function updateCalendarEvent() {
    const event = {
      summary: meetingTitle,
      description: description,
      start: {
        dateTime: startDate,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      end: {
        dateTime: endDate,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      attendees: collaborators,
      conferenceData: {
        createRequest: {
          requestId: nanoid(), // Generate a unique request ID
          conferenceSolutionKey: {
            type: "hangoutsMeet",
          },
        },
      },
      reminders: {
        useDefault: false,
        overrides: [
          { method: "email", minutes: 24 * 60 },
          { method: "popup", minutes: 10 },
        ],
      },
    };

    await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventDetails?.id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ` + accessToken,
        },
        body: JSON.stringify(event),
      }
    )
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log("event data", data);
      });
  }

  async function deleteCalenderEvent() {
    await fetch(
      `https://www.googleapis.com/calendar/v3/calendars/primary/events/${eventDetails?.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ` + accessToken,
        },
      }
    )
      .then((data) => {
        if (data.status === 204) {
          alert("event deleted successfully");
        }
      })
      .then((data) => {
        alert(data);
      });
  }

  const collaboratorsData = [
    { id: 1, email: "alex@intellimorph.ai" },
    { id: 2, email: "hameesha.rantharu@intellimorph.ai" },
    { id: 3, email: "pasan.manohara@intellimorph.ai" },
    { id: 4, email: "supun.chalaka@intellimorph.ai" },
    { id: 5, email: "pushpamal.chathuranga@intellimorph.ai" },
    { id: 6, email: "pathum.chinthaka@intellimorph.ai" },
  ];

  const handleCollaboratorsChange = (e) => {
    const selectedIds = e.value;
    const selectedCollaborators = collaboratorsData
      .filter((collaborator) => selectedIds.includes(collaborator.id))
      .map((collaborator) => ({ email: collaborator.email }));

    setCollaborators(selectedCollaborators);
  };

  const resetEventData = () => {
    setMeetingTitle("");
    setStartDate(null);
    setEndDate(null);
    setCollaborators([]);
    setDescription("");
    setEventDetails("");
  };

  useEffect(() => {
    if (eventDetails.status === "confirmed") {
      closeModal();
      setEventDetails("");
      resetEventData("");
    }
  }, [eventDetails]);

  return (
    <div>
      <Modal isOpen={showModal} toggle={closeModal} centered={true}>
        <ModalHeader toggle={closeModal}>Schedule Meeting</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="meetingTitle">Meeting Title</Label>
              <Input
                type="text"
                name="title"
                id="meetingTitle"
                value={meetingTitle}
                onChange={(e) => setMeetingTitle(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <DateTimePickerComponent
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="endDate">End Date</Label>
              <DateTimePickerComponent
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="collaborators">Collaborators</Label>
              <MultiSelectComponent
                id="collaborators"
                dataSource={collaboratorsData}
                fields={{ text: "email", value: "id" }}
                placeholder="Select collaborators"
                // mode="CheckBox"
                value={collaborators}
                onChange={handleCollaboratorsChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Description</Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={createGoogleMeetEvent}>
            Save
          </Button>
          <Button color="warning" onClick={updateCalendarEvent}>
            Update
          </Button>
          <Button color="danger" onClick={deleteCalenderEvent}>
            Remove Event
          </Button>
          <Button color="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ArrangeMeetingModal;
