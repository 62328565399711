import React, { useState, useEffect } from "react";
import "./DocumentUpload.css";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateJobPostingMutation } from "../../newJobPostingSlice";
import toast, { Toaster } from "react-hot-toast";
import { JobStatuses } from "../../../../enums/jobStatuses";
import { useGetJobByGuidQuery } from "../../../MyActions/MyActionsSlice";

export default function DocumentUpload() {
  const { jobGuid } = useParams();

  const [jobDetails, setJobDetails] = useState(null);
  const {
    data: jobData,
    isLoading: jobDataIsLoading,
    isFetching: jobDataIsFetching,
    isSuccess: jobDataIsSuccess,
    isError: jobDataIsError,
    error: jobDataError,
  } = useGetJobByGuidQuery(jobGuid, { skip: !jobGuid });

  const navigate = useNavigate();
  const [isCoverLetterRequired, setIsCoverLetterRequired] = useState(
    jobDetails?.isCoverLetterRequired
  );
  const [updateJobPosting, { isLoading: updateJobPostingIsLoading }] =
    useUpdateJobPostingMutation();

  useEffect(() => {
    if (jobData) {
      setJobDetails(jobData);
      setIsCoverLetterRequired(jobData?.isCoverLetterRequired);
    }
  }, [jobData]);

  const handleHandleJobPostingUpdate = async (saveOnly) => {
    try {
      const responsePromise = updateJobPosting({
        guid: jobGuid,
        data: {
          isCoverLetterRequired,
        },
      }).unwrap();

      toast.promise(responsePromise, {
        success: "Cover letter status updated",
        loading: "Loading",
        error: "Something went wrong",
      });

      if (!saveOnly) {
        navigate(
          `/my-actions/new-job-posting/application-form/${jobGuid}/application-questions`
        );
      }
      await responsePromise;
    } catch (error) {
      toast.error(error?.data?.Message);
    }
  };

  return (
    <div className="row document-upload">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="col-5">
        <div className="d-flex flex-column gap-3">
          <div className="information">Resume</div>
          <div className="d-flex justify-content-between">
            Cover Letter
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                checked={isCoverLetterRequired}
                onChange={(e) => setIsCoverLetterRequired(e.target.checked)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4 pe-0">
        <div className="col ps-0">
          <button
            className="btn back-btn"
            onClick={() =>
              navigate(`/my-actions/new-job-posting/interview-plan/${jobGuid}`)
            }
          >
            Back
          </button>
        </div>
        <div className="col-auto pe-0">
          <button
            className="btn back-btn me-2"
            onClick={() => handleHandleJobPostingUpdate(true)}
          >
            Save
          </button>
          <button
            className="btn continue-btn"
            onClick={() => handleHandleJobPostingUpdate(false)}
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
}
