import React, { useEffect, useState } from "react";
import FilterIcon from "../../assets/icons/filter.svg";
import searchIcon from "../../assets/icons/search.svg";
import { ReactComponent as ViewIcon } from "../../assets/icons/ViewIcon.svg";
import DataTable from "../../components/common/DataTable/DataTable";
import FilterModal from "../../components/common/FilterModal/FilterModal";
import "./CandidatePipeline.css";
import pdfIcon from "../../assets/icons/pdf.svg";
import { useLocation } from "react-router-dom";
import {
  useGetCandidatesByJobQuery,
  useGetJobInterviewPipelineStagesQuery,
  useAddCandidateToTalentPoolMutation,
  useUpdateAppliedJobStatusMutation,
} from "./CandidatePipelineSlice";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import talantPoolIcon from "../../assets/icons/talant-pool.svg";
import talantPoolIconDisabaled from "../../assets/icons/talant-pool-disabled.svg";
import AddToTalentPoolModal from "../../components/AddToTalentPoolModal/AddToTalentPoolModal";
import toast, { Toaster } from "react-hot-toast";
import { useGetTalentPoolCandidatesQuery } from "../TalentPool/TalentPoolSlice";
import { useGetAppliedJobStatusesQuery } from "../commonDataSlice/staticDataSlice";
import SmallLoadingSpinner from "../../components/common/SmallLoadingSpinner/SmallLoadingSpinner";
import { useHandleAttachmentDownload } from "../../hooks/useHandleAttachmentDownload";

const CandidatePipeline = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobGuid = searchParams.get("jobGuid");
  const { handleAttachmentDownload } = useHandleAttachmentDownload();

  const [initialGuid, setInitialGuid] = useState("");
  const [stageName, setStageName] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [jobInterviewPipelineStages, setJobInterviewPipelineStages] = useState(
    []
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [jobInterviewPipelinestageGuid, setJobInterviewPipelinestageGuid] =
    useState("");
  const [interviewDate, setInterviewDate] = useState("");
  const [query, setQuery] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState({
    jobInterviewPipelineStageGuid: "",
    interviewDate: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [candidateGuid, setCandidateGuid] = useState("");
  const [talentPoolCandidates, setTalentPoolCandidates] = useState([]);
  const [appliedJobStatusData, setAppliedJobStatusData] = useState([]);
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [attachmentLinks, setAttachmentLinks] = useState([]);
  const [attachmentFileName, setAttachmentFileName] = useState("");

  const dateFomatter = (dateString) => {
    return dateString ? format(parseISO(dateString), "dd/MM/yyyy") : "N/A";
  };

  const [
    addCandidateToTalentPool,
    { isLoading: addCandidateToTalentPoolIsLoading },
  ] = useAddCandidateToTalentPoolMutation();
  const [
    updateAppliedJobStatus,
    { isLoading: updateAppliedJobStatusIsLoading },
  ] = useUpdateAppliedJobStatusMutation();

  const {
    data: jobInterviewPipelineStagesData,
    isLoading: jobInterviewPipelineStagesDataIsLoading,
    isFetching: jobInterviewPipelineStagesDataIsFetching,
    isSuccess: jobInterviewPipelineStagesDataIsSuccess,
    isError: jobInterviewPipelineStagesDataIsError,
    error: jobInterviewPipelineStagesDataError,
    refetch: jobInterviewPipelineStagesDataRefetch,
  } = useGetJobInterviewPipelineStagesQuery(jobGuid);

  const {
    data: jobCandidateData,
    isLoading: jobCandidateDataIsLoading,
    isFetching: jobCandidateDataIsFetching,
    isSuccess: jobCandidateDataIsSuccess,
    isError: jobCandidateDataIsError,
    error: jobCandidateDataError,
  } = useGetCandidatesByJobQuery({
    page: page,
    pageSize: pageSize,
    jobGuid: jobGuid,
    jobInterviewPipelineStageGuid: filters.jobInterviewPipelineStageGuid,
    interviewDate: filters.interviewDate,
    query: query,
  });

  const {
    data: talentPoolCandidatesData,
    isLoading: talentPoolCandidatesDataIsLoading,
    isFetching: talentPoolCandidatesDataIsFetching,
    isSuccess: talentPoolCandidatesDataIsSuccess,
    isError: talentPoolCandidatesDataIsError,
    error: talentPoolCandidatesDataError,
  } = useGetTalentPoolCandidatesQuery();

  const {
    data: appliedJobStatusList,
    isLoading: appliedJobStatusListIsLoading,
    isFetching: appliedJobStatusListIsFetching,
    isSuccess: appliedJobStatusListIsSuccess,
    isError: appliedJobStatusListIsError,
    error: appliedJobStatusListError,
  } = useGetAppliedJobStatusesQuery();

  useEffect(() => {
    if (jobInterviewPipelineStagesData) {
      setJobInterviewPipelineStages(jobInterviewPipelineStagesData);
      setStageName(jobInterviewPipelineStagesData[0]?.name);
      setInitialGuid(jobInterviewPipelineStagesData[0]?.guid);
    }
  }, [jobInterviewPipelineStagesData]);

  useEffect(() => {
    if (jobCandidateData && stageName) {
      const data = jobCandidateData.items.map((item) => ({
        ...item,
        appliedJobPipelineStage: item.appliedJobPipelineStages.filter(
          (stage) => stage.jobInterviewPipelineStage.name === stageName
        ),
      }));
      const modifiedData = data.map((item) =>
        item.appliedJobPipelineStage.length
          ? {
              ...item,
              appliedJobGuid: item?.guid,
              appliedOn: item?.appliedOn,
              name: item?.candidate.name,
              email: item?.candidate.email,
              jobInterviewPipelineStageName:
                item.appliedJobPipelineStage[0].jobInterviewPipelineStage.name,
              dateAssigned: dateFomatter(
                item.appliedJobPipelineStage[0].dateAssigned
              ),
              interviewStatus: item.appliedJobPipelineStage[0].status?.name,
              interviewStatusGuid: item.appliedJobPipelineStage[0].status?.guid,
              appliedJobStatus: item.appliedJobStatus?.name,
              appliedJobStatusGuid: item.appliedJobStatus?.guid,
              marks: item.appliedJobPipelineStage[0].marks,
              feedback: item.appliedJobPipelineStage[0].feedback,
            }
          : ""
      );
      setCandidates(modifiedData.filter((data) => data));
      setTotal(jobCandidateData.total);
    }
  }, [jobCandidateData, stageName]);

  useEffect(() => {
    if (talentPoolCandidatesData) {
      const data = talentPoolCandidatesData?.ids?.map(
        (id) => talentPoolCandidatesData?.entities[id]
      );
      setTalentPoolCandidates(data);
    }
    if (talentPoolCandidatesDataError) {
      toast.error(talentPoolCandidatesDataError?.data?.Message, {
        duration: 4000,
      });
      return;
    }
  }, [talentPoolCandidatesData, talentPoolCandidatesDataError]);

  useEffect(() => {
    if (appliedJobStatusList) {
      const data = appliedJobStatusList?.ids?.map(
        (id) => appliedJobStatusList?.entities[id]
      );
      setAppliedJobStatusData(data);
    }
    if (appliedJobStatusListIsError) {
      toast.error(appliedJobStatusListError?.data?.Message);
      return;
    }
  }, [appliedJobStatusList, appliedJobStatusListIsError]);

  function closeModal() {
    setShowFilterModal(false);
  }

  const addToPoolOnClick = (props) => {
    setShowModal(!showModal);
    setCandidateGuid(props?.candidate?.guid);
  };

  const candidateDatacolumns = [
    {
      key: "Employee",
      field: "name",
      headerText: "Employee",
      width: "120",
      customAttributes: {
        class: "candidate-first-column",
      },
    },
    { key: "Email", field: "email", headerText: "Email", width: "200" },
    {
      key: "InterviewDate",
      field: "dateAssigned",
      headerText: "Interview Date",
      width: "140",
      format: "yMd",
    },
    {
      key: "marks",
      field: "marks",
      headerText: "Marks",
      width: "90",
    },
    {
      key: "InterviewStatus",
      field: "interviewStatus",
      headerText: "Interview Status",
      width: "150",
      template: (props) => (
        <label
          htmlFor="status"
          className={`status-badge ${getStatusColor(props?.interviewStatus)}`}
        >
          {props?.interviewStatus}
        </label>
      ),
    },
    {
      key: "AppliedJobStatus",
      field: "appliedJobStatus",
      headerText: "Hiring Status",
      width: "150",
      template: (props) => (
        <label
          htmlFor="status"
          className={`status-badge ${getStatusColor(props?.appliedJobStatus)}`}
        >
          {props?.appliedJobStatus}
        </label>
      ),
    },
    {
      key: "Resume",
      field: "resume",
      headerText: "Resume",
      alignment: "center",
      width: "110",

      template: (props) => (
        <div className="position-relative">
          <img
            src={pdfIcon}
            alt="resume"
            width={25}
            height={25}
            onClick={() =>
              handleAttachmentDownload(props.candidate?.attachments[0])
            }
            style={{ cursor: "pointer" }}
          />
          {isFileDownloading &&
            attachmentFileName === props.candidate?.attachments[0].fileName && (
              <SmallLoadingSpinner />
            )}
        </div>
      ),
    },
    {
      key: "",
      field: "",
      headerText: "Action",
      alignment: "center",
      width: "210",
      template: (props) => (
        <div className="option-icons-container">
          <div className="d-flex gap-2 align-items-center">
            <div>
              <ViewIcon
                onClick={() => {
                  navigate(
                    `/job-candidates/${props?.name}/${props?.appliedJobGuid}?jobGuid=${jobGuid}`
                  );
                }}
              />
            </div>
            <div>
              <button
                className="talent-pool-btn"
                onClick={
                  talentPoolCandidates.some(
                    (candidate) =>
                      candidate.candidateGuid === props?.candidate?.guid
                  )
                    ? () =>
                        toast.error(
                          "This candidate has already added to the talent pool",
                          {
                            duration: 2500,
                            icon: "⚠️",
                          }
                        )
                    : () => addToPoolOnClick(props)
                }
              >
                <img
                  src={
                    talentPoolCandidates.some(
                      (candidate) =>
                        candidate.candidateGuid === props?.candidate?.guid
                    )
                      ? talantPoolIconDisabaled
                      : talantPoolIcon
                  }
                  alt="talent-pool"
                />
              </button>
            </div>
            <div>
              <select
                id={`jobStatusSelect-${props?.id}`}
                className="form-select form-select-sm"
                onChange={(e) =>
                  handleChangeJobStatusChange(
                    e.target.value,
                    props?.appliedJobGuid
                  )
                }
                title="Update Job Status"
                value={props?.appliedJobStatusGuid}
                disabled={updateAppliedJobStatusIsLoading}
              >
                <option value={""}>Change Hiring Status</option>
                {appliedJobStatusData
                  .filter((status) => status.id !== 6) // Filter out items where id is 6
                  .map((status) => (
                    <option key={status.guid} value={status.guid}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      ),
    },
  ];

  var getStatusColor = (status) => {
    switch (status) {
      case "Scheduled":
        return "scheduled-badge";
      case "Pending":
        return "pending-badge";
      case "Completed":
        return "completed-badge";
      case "Hired":
        return "hired-badge";
      case "Approved":
        return "approved-badge";
      case "Applied":
        return "applied-badge";
      case "Rejected":
        return "rejected-badge";
      default:
        return "default-badge";
    }
  };

  const filterOptions = [
    {
      name: "Job Interview Pipeline Stage Guid",
      field: "jobInterviewPipelineStageGuid",
      type: "text",
      value: jobInterviewPipelinestageGuid,
      setValue: setJobInterviewPipelinestageGuid,
    },
    {
      name: "Interview Date",
      field: "interviewDate",
      type: "text",
      value: interviewDate,
      setValue: setInterviewDate,
    },
  ];

  const filterOnClick = () => {
    setJobInterviewPipelinestageGuid("");
    setInterviewDate("");
    setShowFilterModal(true);
  };

  const handleSearchFilterOnClick = (data) => {
    setFilters({
      jobInterviewPipelineStageGuid: data?.jobInterviewPipelineStageGuid
        ? data?.jobInterviewPipelineStageGuid
        : "",
      interviewDate: data?.interviewDate ? data?.interviewDate : "",
    });
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const addCandidateToTalentPoolOnClick = async (data) => {
    try {
      const response = await addCandidateToTalentPool({
        candidateGuid: candidateGuid,
        feedback: data.feedback,
        score: data.score.toString(),
      });
      if (response?.error) {
        toast.error(response?.error?.data?.title || "Something Went wrong", {
          duration: 2500,
        });
        return;
      }
      toast.success("Candidate Added to Talent Pool Successfully", {
        duration: 1800,
      });
      setShowModal(!showModal);
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 2500,
      });
    }
  };

  const handleChangeJobStatusChange = async (statusGuid, guid) => {
    try {
      if (statusGuid === "") {
        return;
      }
      const requestPromise = updateAppliedJobStatus({ statusGuid, guid });
      toast.promise(requestPromise, {
        success: "Applied Job Status Updated",
        loading: "Status Updating...",
        error: "Something Went Wrong...!",
      });
    } catch (error) {
      toast.error(error?.data?.Message);
    }
  };

  return (
    <div>
      {(jobInterviewPipelineStagesDataIsFetching ||
        addCandidateToTalentPoolIsLoading) && <LoadingSpinner />}
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="row gx-1 mt-1">
        {jobInterviewPipelineStages.map((card) => (
          <div
            className="col candicate-pipeline-cards-col"
            key={card.guid}
            onClick={() => setStageName(card.name)}
          >
            <div
              className={`candicate-pipeline-cards ${
                stageName === card.name ? "active" : ""
              }`}
            >
              <div className="candicate-pipeline-card-body p-2 d-flex align-items-center">
                {/* <div className="candicate-pipeline-card-value d-flex align-items-center justify-content-center">
                  {card.value}
                </div> */}
                <div className="card-title m-0">{card.name}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="candicate-pipeline-content mt-2">
        <div className="row gap-2 justify-content-between align-items-center mb-3 pt-3">
          <div className="col-12 col-md-2 border-2 px-4">
            <div className="d-flex">
              <div>
                <select
                  className="border-0 page-selector"
                  onChange={(e) => setPageSize(e.target.value)}
                >
                  <option value={10}>Display 10 Candidates</option>
                  <option value={20}>Display 20 Candidates</option>
                  <option value={50}>Display 50 Candidates</option>
                  <option value={100}>Display 100 Candidates</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-auto border-2 d-flex py-3 px-4">
            <div style={{ position: "relative" }}>
              <div className="search-icon">
                <img src={searchIcon} alt="Search" />
              </div>
              <div className="px-2">
                <input
                  id="file-search-input"
                  type="text"
                  className="form-control custom-input w-100 pl-3 "
                  placeholder="Search Candidate"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div>
              <button onClick={filterOnClick} className="btn p-0">
                <img src={FilterIcon} alt="filter" />
              </button>
            </div>
          </div>
        </div>
        <FilterModal
          showModal={showFilterModal}
          closeModal={closeModal}
          filterOptions={filterOptions}
          filters={filters}
          setFilters={setFilters}
          confirmSubmit={handleSearchFilterOnClick}
        />
        <div className="row">
          <div>
            <DataTable
              data={candidates}
              columns={candidateDatacolumns}
              dataKey={initialGuid}
              defaultPaging={false}
              setPage={setPage}
              pageSize={pageSize}
              //   pageCount={totalPages}
              totalRecords={total}
              selectable={false}
              tableName={"candidate-pipeline-details-table"}
            />
          </div>
        </div>
        <AddToTalentPoolModal
          showModal={showModal}
          closeModal={setShowModal}
          handleSubmit={(data) => addCandidateToTalentPoolOnClick(data)}
        />
      </div>
    </div>
  );
};

export default CandidatePipeline;
