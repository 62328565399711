import { createEntityAdapter, nanoid } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const stagesAdapter = createEntityAdapter({
  selectId: (item) => item?.guid,
});

const initialState = stagesAdapter.getInitialState();

export const newJobPostingSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStages: builder.query({
      query: (guid) => `/jobs/${guid}/interview-pipeline-stages`,
      transformResponse: (response) => {
        const loadedData = response;
        return stagesAdapter.setAll(initialState, loadedData);
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "InterviewPipelineStages", id: "LIST" }];
        } else {
          return [
            { type: "InterviewPipelineStages", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "InterviewPipelineStages",
              id: id.guid,
            })),
          ];
        }
      },
    }),
    addNewJobPosting: builder.mutation({
      query: (data) => ({
        url: `/jobs`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "" }],
    }),
    markForAutomation: builder.mutation({
      query: (data) => ({
        url: `/jobs/questions/automation:mark`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Job" },
        { type: "Jobs" },
        { type: "MyJobs" },
      ],
    }),
    unmarkFromAutomation: builder.mutation({
      query: (data) => ({
        url: `/jobs/questions/automation:unmark`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Job" },
        { type: "Jobs" },
        { type: "MyJobs" },
      ],
    }),
    updateJobPosting: builder.mutation({
      query: ({ guid, data }) => ({
        url: `/jobs/${guid}`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Job" },
        { type: "Jobs" },
        { type: "MyJobs" },
      ],
    }),
    addJobInterviewPipelineStages: builder.mutation({
      query: ({ guid, data }) => ({
        url: `/jobs/${guid}/interview-pipeline-stages`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "InterviewPipelineStages", id: "LIST" },
      ],
    }),
    addApplicationQuestions: builder.mutation({
      query: ({ guid, questions }) => ({
        url: `/jobs/${guid}/questions`,
        method: "PUT",
        body: {
          questions,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Jobs" },
        { type: "Job" },
        { type: "MyJobs" },
      ],
    }),
    deleteApplicationQuestion: builder.mutation({
      query: (guid) => ({
        url: `/jobs/questions/${guid}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Jobs" },
        { type: "Job" },
        { type: "MyJobs" },
      ],
    }),
    deleteInterviewPipelineStage: builder.mutation({
      query: (guid) => ({
        url: `/jobs/interview-pipeline-stages/${guid}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "InterviewPipelineStages" },
      ],
    }),
    deleteInterviewPipelineStageQuestion: builder.mutation({
      query: (guid) => ({
        url: `/jobs/interview-pipeline-stages/questions/${guid}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "InterviewPipelineStages" },
      ],
    }),
    deleteMemberFromTeam: builder.mutation({
      query: ({ guid, teamGuid }) => ({
        url: `/jobs/teams/${teamGuid}/members/${guid}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Users" },
        { type: "Teams" },
        { type: "Team" },
        { type: "Jobs" },
        { type: "Job" },
        { type: "MyJobs" },
      ],
    }),
  }),
});

export const {
  // useGetJobInterviewPipelineStagesQuery,
  useGetStagesQuery,
  useAddNewJobPostingMutation,
  useAddJobInterviewPipelineStagesMutation,
  useDeleteInterviewPipelineStageMutation,
  useDeleteInterviewPipelineStageQuestionMutation,
  useUpdateJobPostingMutation,
  useAddApplicationQuestionsMutation,
  useDeleteMemberFromTeamMutation,
  useDeleteApplicationQuestionMutation,
  useMarkForAutomationMutation,
  useUnmarkFromAutomationMutation,
} = newJobPostingSlice;
