import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import saveIcon from "../../../../assets/icons/save.svg";
import saveDisabledIcon from "../../../../assets/icons/saveIconDisabled.svg";
import "./InterviewKit.css";
import {
  useGetAppliedJobInterviewQuestionsQuery,
  useUpdateAppliedJobInterviewQuestionsMutation,
} from "../../CandidatePipelineSlice";
import LoadingSpinner from "../../../../components/common/LoadingSpinner/LoadingSpinner";
import toast, { Toaster } from "react-hot-toast";

export default function InterviewKit({ stageGuid }) {
  const [feedback, setFeedback] = useState({ row: "", value: "" });
  const [score, setScore] = useState({ row: "", value: "" });
  const [questionsData, setQuestionsData] = useState([]);
  const [questionGuid, setQuestionGuid] = useState("");

  const [
    updateAppliedJobInterviewQuestions,
    { isLoading: updateAppliedJobInterviewQuestionsIsLoading },
  ] = useUpdateAppliedJobInterviewQuestionsMutation();

  const {
    data: appliedJobInterviewQuestionsData,
    isLoading: appliedJobInterviewQuestionsDataIsLoading,
    isFetching: appliedJobInterviewQuestionsDataIsFetching,
    isSuccess: appliedJobInterviewQuestionsDataIsSuccess,
    isError: appliedJobInterviewQuestionsDataIsError,
    error: appliedJobInterviewQuestionsDataError,
    refetch: appliedJobInterviewQuestionsDataRefetch,
  } = useGetAppliedJobInterviewQuestionsQuery(stageGuid);

  useEffect(() => {
    if (appliedJobInterviewQuestionsData) {
      const data = appliedJobInterviewQuestionsData.map((question, index) => ({
        id: index + 1,
        ...question,
      }));
      setQuestionsData(data);
    }
  }, [appliedJobInterviewQuestionsData]);

  const handleChangeScore = (row, event) => {
    const newValue = event.target.value;
    if (!/^\d*$/.test(newValue)) {
      toast.error("Score must be a number between 0 and 10");
      return;
    }

    const numericValue = parseInt(newValue, 10);

    if (numericValue > 10) {
      toast.error("Score cannot exceed 10");
      return;
    }
    setScore({ row: row.feedback?.guid, value: newValue });
  };

  const handleFeedbackChange = (row, event) => {
    setFeedback({
      row: row.feedback?.guid,
      value: event.target.value,
    });
  };

  const resetFields = () => {
    setFeedback({ row: "", value: "" });
    setScore({ row: "", value: "" });
  };

  const updateQuestionOnClick = async (question) => {
    const feedbackValue =
      feedback.row === question.feedback?.guid
        ? feedback.value
        : question.feedback?.feedback;
    const scoreValue =
      score.row === question.feedback?.guid
        ? score.value
        : question.feedback?.score;

    if (!feedbackValue) {
      toast.error("Feedback is required");
      return;
    }

    try {
      const response = await updateAppliedJobInterviewQuestions({
        questionGuid: question.guid,
        data: {
          feedbacks: [
            {
              guid: question.feedback?.guid,
              feedback: feedbackValue,
              score: parseInt(scoreValue),
            },
          ],
        },
      });
      if (response?.error) {
        toast.error(response?.error?.data?.title || "Something went wrong", {
          duration: 2500,
        });
        return;
      }
      toast.success("Question Updated Successfully", {
        duration: 1800,
      });
      resetFields();
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 2500,
      });
    }
  };

  return (
    <div className="py-3">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {(appliedJobInterviewQuestionsDataIsFetching ||
        updateAppliedJobInterviewQuestionsIsLoading) && <LoadingSpinner />}
      <div className="card custom-card px-0 pb-0">
        {questionsData?.length > 0 ? (
          <Table className="preliminary-question-table m-0" responsive>
            <thead>
              <tr>
                <th></th>
                <th className="questions">Questions</th>
                <th className="feedback"> Feedback</th>
                <th className="score">Score</th>
                <th className="options">Options</th>
              </tr>
            </thead>

            <tbody>
              {questionsData.map((question) => (
                <tr key={question.guid}>
                  <td style={{ textAlign: "center" }}>{question.id}</td>
                  <td className="question-text">{question.question}</td>
                  <td>
                    <textarea
                      type="text"
                      className="form-control feedback-text"
                      rows={1}
                      value={
                        feedback.row === question.feedback?.guid
                          ? feedback.value
                          : question.feedback?.feedback
                      }
                      onChange={(e) => handleFeedbackChange(question, e)}
                    />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <input
                        className="feedback-input score"
                        type="text"
                        value={
                          score.row === question.feedback?.guid
                            ? score.value
                            : question.feedback?.score
                        }
                        max={10}
                        onChange={(e) => handleChangeScore(question, e)}
                      />
                      <span>/10</span>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-3 options">
                      <img
                        src={saveIcon}
                        alt="save"
                        width={16}
                        height={16}
                        onClick={() => updateQuestionOnClick(question)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p className="p-3 fw-bold">No Questions Available</p>
        )}
      </div>
    </div>
  );
}
