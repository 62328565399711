import React, { useState, useEffect } from "react";
import { useGetEmailTemplatesQuery } from "../../features/SettingsPage/settingsSlice";
import toast from "react-hot-toast";
import { AsyncPaginate } from "react-select-async-paginate";
const EmailTemplateDropdown = ({
  selectedEmailTemplate,
  setSelectedEmailTemplate,
}) => {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  const {
    data: emailTemplatesData,
    isLoading: emailTemplatesDataIsLoading,
    isFetching: emailTemplatesDataIsFetching,
    isSuccess: emailTemplatesDataIsSuccess,
    isError: emailTemplatesDataIsError,
    error: emailTemplatesDataError,
  } = useGetEmailTemplatesQuery({
    page,
    pageSize: 10,
    query,
  });

  useEffect(() => {
    if (emailTemplatesData) {
      const data = emailTemplatesData?.ids?.map(
        (id) => emailTemplatesData?.entities[id]
      );

      const templates = data[0];
      setEmailTemplates(templates?.items);
    }
    if (emailTemplatesDataIsError) {
      toast.error(emailTemplatesDataError?.data?.Message);
    }
  }, [emailTemplatesData, emailTemplatesDataIsError]);

  const loadPageOptions = async (q, prevOptions, { page }) => {
    const options = emailTemplates.map((item) => ({
      value: item.guid,
      label: item.name,
    }));
    setPage(page);
    return {
      options,
      hasMore: options.length >= 10,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleInputChange = (newQuery) => {
    setQuery(newQuery?.toLowerCase());
    setPage(1);
  };

  return (
    <>
      <AsyncPaginate
        id="confirmEmailTemplate"
        additional={{ page }}
        value={selectedEmailTemplate}
        loadOptions={loadPageOptions}
        onChange={setSelectedEmailTemplate}
        onInputChange={handleInputChange}
        inputValue={query}
      />
    </>
  );
};

export default EmailTemplateDropdown;
