import { SliderComponent } from "@syncfusion/ej2-react-inputs";
import React, { useEffect, useRef, useState } from "react";
import batchEditIcon from "../../../../assets/icons/batchEdit.svg";
import batchEditDisabledIcon from "../../../../assets/icons/batchEdit-disabled.svg";
import "./PerformanceReview.css";
import {
  useGetAppliedJobInterviewPipelineStatusesQuery,
  useAddFeedbackToAppliedJobInterviewStageMutation,
  useAddCommentToAppliedJobInterviewStageMutation,
  useUpdateCommentToAppliedJobInterviewStageMutation,
  useUpdateAppliedJobInterviewPipelineStatusMutation,
} from "../../CandidatePipelineSlice";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "../../../../components/common/LoadingSpinner/LoadingSpinner";
import { useParams } from "react-router-dom";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { MentionComponent } from "@syncfusion/ej2-react-dropdowns";
import editIcon from "../../../../assets/icons/EditIcon.svg";
import saveIcon from "../../../../assets/icons/save.svg";
import editIconDisabled from "../../../../assets/icons/EditIcon-disabled.svg";
import saveIconDisabled from "../../../../assets/icons/saveIconDisabled.svg";
import { format, parseISO } from "date-fns";
import dotIcon from "../../../../assets/icons/dot-small.svg";
import { selectCurrentUser } from "../../../auth/authSlice";
import { useSelector } from "react-redux";
import { useGetUsersQuery } from "../../../commonDataSlice/usersSlice";
import { appliedJobInterviewPiplineStageStatus } from "../../../../enums/appliedJobInterviewPiplineStageStatus";

export default function PerformanceReview({ data }) {
  const { appliedJobGuid } = useParams();
  const addCommentRef = useRef();
  const user = useSelector(selectCurrentUser);

  const [rangeSliderValue, setRangeSliderValue] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [comment, setComment] = useState("");
  const [statusGuid, setStatusGuid] = useState("");
  const [updatedComment, setUpdatedComment] = useState("");
  const [updatedCommentGuid, setUpdatedCommentGuid] = useState("");
  const [comments, setComments] = useState([]);
  const [mentionedUsersGuidList, setMentionedUsersGuidList] = useState([]);
  const [updateMentionedUsersGuidList, setUpdateMentionedUsersGuidList] =
    useState([]);
  const [users, setUsers] = useState([]);
  const [
    appliedJobInterviewPipelineStageStatuses,
    setAppliedJobInterviewPipelineStageStatuses,
  ] = useState([]);
  const [
    addFeedbackToAppliedJobInterviewStage,
    { isLoading: addFeedbackToAppliedJobInterviewStageIsLoading },
  ] = useAddFeedbackToAppliedJobInterviewStageMutation();

  const [
    addCommentToAppliedJobInterviewStage,
    {
      isLoading: addCommentToAppliedJobInterviewStageIsLoading,
      isSuccess: addCommentToAppliedJobInterviewStageIsSuccess,
    },
  ] = useAddCommentToAppliedJobInterviewStageMutation();

  const [
    updateCommentToAppliedJobInterviewStage,
    { isLoading: updateCommentToAppliedJobInterviewStageIsLoading },
  ] = useUpdateCommentToAppliedJobInterviewStageMutation();

  const [
    updateAppliedJobInterviewPipelineStatus,
    { isLoading: updateAppliedJobInterviewPipelineStatusIsLoading },
  ] = useUpdateAppliedJobInterviewPipelineStatusMutation();

  const {
    data: usersData,
    isLoading: usersDataIsLoading,
    isFetching: usersDataIsFetching,
    isSuccess: usersDataIsSuccess,
    isError: usersDataIsError,
    error: usersDataError,
  } = useGetUsersQuery();

  const {
    data: appliedJobInterviewPipelineStatusesData,
    isLoading: appliedJobInterviewPipelineStatusesDataIsLoading,
    isFetching: appliedJobInterviewPipelineStatusesDataIsFetching,
    isSuccess: appliedJobInterviewPipelineStatusesDataIsSuccess,
    isError: appliedJobInterviewPipelineStatusesDataIsError,
    error: appliedJobInterviewPipelineStatusesDataError,
  } = useGetAppliedJobInterviewPipelineStatusesQuery();

  useEffect(() => {
    if (usersData) {
      const data = usersData.ids?.map((id) => usersData.entities[id]);
      setUsers(data);
    }
  }, [usersData]);

  useEffect(() => {
    if (appliedJobInterviewPipelineStatusesData) {
      setAppliedJobInterviewPipelineStageStatuses(
        appliedJobInterviewPipelineStatusesData
      );
    }
  }, [appliedJobInterviewPipelineStatusesData]);

  const handleSliderChange = (args) => {
    setRangeSliderValue(args?.value);
  };

  useEffect(() => {
    if (data) {
      setRangeSliderValue(data.feedback?.score);
      setFeedback(data.feedback?.feedbackMessage);
      setComments(data.comments);
      setStatusGuid(data?.statusGuid);
    }
  }, [data]);

  const addFeedbackOnClick = async () => {
    try {
      const response = await addFeedbackToAppliedJobInterviewStage({
        appliedJobGuid: appliedJobGuid,
        guid: data.guid,
        data: {
          feedback: feedback,
          marks: rangeSliderValue,
        },
      });
      if (response?.error) {
        toast.error(response?.error?.data?.title || "Something Went wrong", {
          duration: 2500,
        });
        return;
      }
      toast.success("Feeback Updated Successfully", {
        duration: 1800,
      });
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 2500,
      });
    }
  };

  const addCommentOnClick = async () => {
    if (comment === "") {
      toast.error("Comment is required...!");
      return;
    }

    let guidList = users.filter((user) => comment?.includes(user?.name));
    guidList = guidList.map((item) => item.guid);

    try {
      const response = await addCommentToAppliedJobInterviewStage({
        appliedJobGuid: appliedJobGuid,
        guid: data.guid,
        data: {
          comment: comment,
          mentionedUsersGuidList: guidList,
        },
      });
      if (response?.error) {
        toast.error(response?.error?.data?.title || "Something Went wrong", {
          duration: 2500,
        });
        return;
      }
      toast.success("Comment Added Successfully", {
        duration: 1800,
      });
      addCommentRef.current.value = "";
      setComment("");
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 2500,
      });
    }
  };

  const updateCommentOnClick = async () => {
    const canAdd = Boolean(
      updatedComment && updateMentionedUsersGuidList.length
    );
    if (!canAdd) {
      if (updatedComment === "") {
        toast.error("Comment is required...!");
        return;
      }
    }

    let guidList = users.filter((user) => updatedComment?.includes(user?.name));
    guidList = guidList.map((item) => item.guid);

    try {
      const response = await updateCommentToAppliedJobInterviewStage({
        appliedJobGuid: appliedJobGuid,
        guid: updatedCommentGuid,
        data: {
          comment: updatedComment,
          mentionedUsersGuidList: guidList,
        },
      });
      if (response?.error) {
        toast.error(response?.error?.data?.title || "Something Went wrong", {
          duration: 2500,
        });
        return;
      }
      toast.success("Comment Updated Successfully", {
        duration: 1800,
      });
      notEditComment();
    } catch (error) {
      toast.error(error?.data?.message, {
        duration: 2500,
      });
    }
  };

  const updateStatusOnClick = async () => {
    if (statusGuid) {
      try {
        const response = await updateAppliedJobInterviewPipelineStatus({
          appliedJobGuid: appliedJobGuid,
          guid: data.guid,
          data: {
            statusGuid,
          },
        });
        if (response?.error) {
          toast.error(response?.error?.data?.title || "Something Went wrong", {
            duration: 2500,
          });
          return;
        }
        toast.success("Status Updated Successfully", {
          duration: 1800,
        });
      } catch (error) {
        toast.error(error?.data?.message, {
          duration: 2500,
        });
      }
    }
  };

  const addMentionedUsersToArray = (guid, update = false) => {
    if (update) {
      if (updateMentionedUsersGuidList.length) {
        setUpdateMentionedUsersGuidList([
          ...updateMentionedUsersGuidList,
          guid,
        ]);
      } else {
        setUpdateMentionedUsersGuidList([guid]);
      }
    } else {
      if (mentionedUsersGuidList.length) {
        setMentionedUsersGuidList([...mentionedUsersGuidList, guid]);
      } else {
        setMentionedUsersGuidList([guid]);
      }
    }
  };

  //syncfusion mention

  var actionBegineHandler = function (args) {
    if (args.requestType === "EnterAction") {
      args.cancel = true;
    }
  };

  var displayTemplate = function (data) {
    addMentionedUsersToArray(data.guid);
    return (
      <React.Fragment>
        <span key={data.guid}>@{data.name}</span>
      </React.Fragment>
    );
  };

  var displayTemplateUpdate = function (data) {
    addMentionedUsersToArray(data.guid, true);
    return (
      <React.Fragment>
        <span key={data.guid}>@{data.name}</span>
      </React.Fragment>
    );
  };

  function extractTextContent(htmlString, update = false) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const textContent = doc.body.textContent;
    if (update) {
      setUpdatedComment(textContent);
    } else {
      setComment(textContent);
    }
  }

  const editComment = (comment) => {
    setUpdatedCommentGuid(comment?.guid);
    setUpdatedComment(comment?.comment);
  };

  const notEditComment = () => {
    setUpdatedCommentGuid(null);
    setUpdatedComment("");
    setUpdateMentionedUsersGuidList([]);
  };

  const dateFomatter = (dateString) => {
    return format(parseISO(dateString), "dd/MM/yyyy");
  };

  const timeFomatter = (dateString) => {
    return format(parseISO(dateString), "HH:mm:ss");
  };

  const handleSubmit = async () => {
    if (
      statusGuid === appliedJobInterviewPiplineStageStatus.COMPLETED ||
      statusGuid === appliedJobInterviewPiplineStageStatus.REJECTED
    ) {
      if (!feedback) {
        toast.error("Feedback is required...!");
        return;
      }

      if (!rangeSliderValue) {
        toast.error("Score is required...!");
        return;
      }
    }

    if (statusGuid !== "") {
      await updateStatusOnClick();
      await addFeedbackOnClick();
    } else {
      toast.error("Status Should be selected or updated...!");
      return;
    }
  };

  return (
    <div className="performance-review">
      {(addFeedbackToAppliedJobInterviewStageIsLoading ||
        addCommentToAppliedJobInterviewStageIsLoading ||
        updateCommentToAppliedJobInterviewStageIsLoading ||
        updateAppliedJobInterviewPipelineStatusIsLoading) && <LoadingSpinner />}
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="row mb-2">
        <div className="col">
          <div className="card custom-card p-3">
            <div className="d-flex justify-content-end gap-2 align-items-center">
              {/* <button className="batch-edit" onClick={() => setIsEdit(!isEdit)}>
                <img
                  src={isEdit ? batchEditDisabledIcon : batchEditIcon}
                  alt="batch-edit"
                  width={22}
                  height={22}
                />
              </button> */}
              <div>
                <select
                  className="border-0 candidate-selector"
                  onChange={(e) => setStatusGuid(e.target.value)}
                  value={statusGuid}
                >
                  <option value="">Selected to the next stage</option>
                  {appliedJobInterviewPipelineStageStatuses.map((status) => (
                    <option key={status.id} value={status.guid}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="text-secondary fw-bold">
              <div>
                <h6>Score</h6>
                <span>{rangeSliderValue + "%"}</span>
              </div>
              <SliderComponent
                id="slider"
                type="MinRange"
                value={rangeSliderValue}
                width="80%"
                tooltip="After"
                immediateRender={true}
                enableAnimation={true}
                change={handleSliderChange}
              ></SliderComponent>
            </div>
            <div>
              <h6 className="text-secondary">Feedback</h6>
              <div>
                <textarea
                  className="feedback-text-area"
                  name="feedback"
                  id="feedback"
                  cols={100}
                  rows={5}
                  placeholder="Write something..."
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="d-flex justify-content-end pt-3">
              <div>
                <button className="primary-button" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col">
          <div className="card custom-card p-3">
            <div className="pt-3">
              <h6 className="text-secondary">Add Comment</h6>
              <div>
                <div className="control-section w-100" id="rte">
                  <div className="rte-control-section w-100">
                    <RichTextEditorComponent
                      ref={addCommentRef}
                      className="feedback-text-area add-comment"
                      id="mention_integration"
                      placeholder="Write a comment here..."
                      actionBegin={actionBegineHandler.bind(this)}
                      change={(args) => extractTextContent(args.value)}
                    >
                      <Inject
                        services={[
                          Toolbar,
                          Image,
                          Link,
                          HtmlEditor,
                          QuickToolbar,
                        ]}
                      />
                    </RichTextEditorComponent>
                  </div>
                </div>
                <MentionComponent
                  id="mentionEditor"
                  target="#mention_integration_rte-edit-view"
                  suggestionCount={8}
                  showMentionChar={false}
                  allowSpaces={true}
                  dataSource={users}
                  fields={{ text: "name" }}
                  displayTemplate={displayTemplate.bind(this)}
                ></MentionComponent>
                <div className="d-flex justify-content-end pt-3">
                  <div>
                    <button
                      className="primary-button"
                      onClick={addCommentOnClick}
                    >
                      Comment
                    </button>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column gap-2 mt-3">
                {comments?.map((comment) => (
                  <div
                    key={comment?.guid}
                    className="d-flex gap-2 align-items-center justify-content-between"
                    style={{ width: "80%" }}
                  >
                    <div className="col-10 control-section w-100" id="rte">
                      <div
                        className="rte-control-section w-100"
                        style={{ position: "relative" }}
                      >
                        <RichTextEditorComponent
                          className="feedback-text-area comments"
                          id={`mention_integration_${comment.guid}`}
                          placeholder="Write a comment here..."
                          actionBegin={actionBegineHandler.bind(this)}
                          change={(args) =>
                            extractTextContent(args.value, true)
                          }
                          value={comment?.comment}
                          enabled={updatedCommentGuid === comment.guid}
                        >
                          <Inject
                            services={[
                              Toolbar,
                              Image,
                              Link,
                              HtmlEditor,
                              QuickToolbar,
                            ]}
                          />
                        </RichTextEditorComponent>
                        <p className="static-text">
                          {comment?.createdBy?.name}
                          {comment?.updatedOn ? (
                            <div>
                              {dateFomatter(comment?.updatedOn)}
                              <span>{timeFomatter(comment?.updatedOn)}</span>
                              <span className="d-flex">
                                <img
                                  style={{ marginRight: "-2px" }}
                                  src={dotIcon}
                                  alt="dot"
                                  width={18}
                                  height={18}
                                />
                                Edited
                              </span>
                            </div>
                          ) : (
                            <div>
                              <span>{dateFomatter(comment?.createdOn)}</span>
                              <span>{timeFomatter(comment?.createdOn)}</span>
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                    <MentionComponent
                      id="mentionEditor"
                      target={`#mention_integration_${comment.guid}_rte-edit-view`}
                      suggestionCount={8}
                      showMentionChar={false}
                      allowSpaces={true}
                      dataSource={users}
                      fields={{ text: "name" }}
                      // itemTemplate={itemTemplate}
                      displayTemplate={displayTemplateUpdate.bind(this)}
                    ></MentionComponent>

                    {user.guid === comment?.createdBy?.guid ? (
                      <div className="d-flex col-auto gap-2">
                        <button
                          className="edit-comment-btn"
                          onClick={() =>
                            updatedCommentGuid === comment.guid
                              ? notEditComment(comment)
                              : editComment(comment)
                          }
                        >
                          <img
                            src={
                              updatedCommentGuid === comment.guid
                                ? editIconDisabled
                                : editIcon
                            }
                            alt="edit"
                            width={25}
                            height={25}
                          />
                        </button>
                        <button
                          className="edit-comment-btn"
                          disabled={updatedCommentGuid !== comment.guid}
                          onClick={updateCommentOnClick}
                        >
                          <img
                            src={
                              updatedCommentGuid === comment.guid
                                ? saveIcon
                                : saveIconDisabled
                            }
                            alt="save"
                          />
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
