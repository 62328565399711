import React, { useEffect, useState } from "react";
import "./ScheduleModal.css";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default function ScheduleModal({
  showModal,
  closeModal,
  handleOnSubmit,
  meetUrl,
  dateAssigned,
}) {
  const [text, setText] = useState("");
  useEffect(() => {
    setText(meetUrl ?? "");
  }, [showModal]);

  return (
    <Modal style={{ padding: 0 }} isOpen={showModal} centered={true}>
      <ModalHeader className="" toggle={() => closeModal(!showModal)}>
        <span className="">Schedule a Meeting</span>
      </ModalHeader>
      <ModalBody className="">
        <input
          className="form-control"
          type="text"
          placeholder="Please enter the meeting URL"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button
          color="secondary"
          className="back-btn"
          onClick={() => closeModal(!showModal)}
          style={{ width: "auto" }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="schedlue-btn"
          onClick={() => handleOnSubmit(text, dateAssigned)}
          style={{ width: "80px", backgroundColor: "#3b5998" }}
        >
          Schedule
        </Button>
      </ModalFooter>
    </Modal>
  );
}
