import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./ApplicationFormLayout.css";

export default function ApplicationFormLayout() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="applicationfrom-layout custom card">
      <div className="row">
        <div className="col">
          <div className="nav-container">
            <h5
              className={`applicationfrom-navlink ${
                location.pathname.includes("/document-upload") ? "active" : ""
              }`}
              onClick={() => navigate("document-upload")}
            >
              Document Upload
            </h5>
            <h5
              className={`applicationfrom-navlink ${
                location.pathname.includes("/application-questions")
                  ? "active"
                  : ""
              }`}
              onClick={() => navigate("application-questions")}
            >
              Application Questions
            </h5>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
