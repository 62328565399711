import React from "react";
import "./SmallLoadingSpinner.css";

export default function SmallLoadingSpinner() {
  return (
    <div className="d-flex justify-content-center small-spinner">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}
