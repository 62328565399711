import { createEntityAdapter, nanoid } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const adapter = createEntityAdapter({
  selectId: (item) => item?.guid,
});

const initialState = adapter.getInitialState();

export const staticDataSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSkills: builder.query({
      query: () => `/skills`,
      transformResponse: (response) => {
        const loadedData = response;
        return adapter.setAll(initialState, loadedData);
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "Skills", id: "LIST" }];
        } else {
          return [
            { type: "Skills", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "Skills",
              id: id.guid,
            })),
          ];
        }
      },
    }),
    addSkill: builder.mutation({
      query: (data) => ({
        url: `/skills`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Skills" }],
    }),
  }),
});

export const { useGetSkillsQuery, useAddSkillMutation } = staticDataSlice;
