import React, { useState, useEffect } from "react";
import "./HiringTeam.css";
import { useNavigate, useParams } from "react-router-dom";
import FilterIcon from "../../../assets/icons/filter.svg";
import searchIcon from "../../../assets/icons/search.svg";
import DataTable from "../../../components/common/DataTable/DataTable";
import AddNewUserModal from "../../../components/AddNewUserModal/AddNewUserModal";
import { useGetJobByGuidQuery } from "../../MyActions/MyActionsSlice";
import deleteIcon from "../../../assets/icons/deleteIcon.svg";
import toast, { Toaster } from "react-hot-toast";
import {
  useCreateTeamForJobMutation,
  useUpdateTeamMutation,
} from "../../commonDataSlice/usersSlice";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";
import { useDeleteMemberFromTeamMutation } from "../newJobPostingSlice";

export default function HiringTeam() {
  const navigate = useNavigate();
  const { jobGuid } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [jobHiringTeam, setJobHiringTeam] = useState({
    guid: "",
    name: "",
    members: [],
  });

  const [members, setMembers] = useState([]);

  const {
    data: jobData,
    isLoading: jobDataIsLoading,
    isFetching: jobDataIsFetching,
    isSuccess: jobDataIsSuccess,
    isError: jobDataIsError,
    error: jobDataError,
  } = useGetJobByGuidQuery(jobGuid, { skip: !jobGuid });

  const [createTeamForJob, { isLoading: createTeamForJobIsLoading }] =
    useCreateTeamForJobMutation();
  const [updateTeam, { isLoading: updateTeamIsLoading }] =
    useUpdateTeamMutation();
  const [deleteMember, { isLoading: deleteMemberIsLoading }] =
    useDeleteMemberFromTeamMutation();

  useEffect(() => {
    if (jobData) {
      setJobHiringTeam(jobData?.team);
      setMembers(
        jobData?.team?.members?.map((member) => ({
          name: member.name,
          guid: member.guid,
          role: member.role?.name,
          roleGuid: member.role?.guid,
        }))
      );
    }
  }, [jobData]);

  const handleSearch = () => {};

  const handleDelete = async (args) => {
    try {
      const responsePromise = deleteMember({
        guid: args.guid,
        teamGuid: jobHiringTeam?.guid,
      }).unwrap();

      toast.promise(responsePromise, {
        success: "Member Deleted Successfully",
        loading: "Loading...",
        error: "Something went wrong...!",
      });

      await responsePromise;
    } catch (error) {
      toast.error(error?.data?.Message);
    }
  };

  const newColumns = [
    {
      key: "name",
      field: "name",
      headerText: "Member Name",
      width: "140",
      alignment: "Left",
    },
    {
      key: "role",
      field: "role",
      headerText: "Role",
      width: "140",
      alignment: "Left",
    },
    {
      key: "options",
      field: "options",
      headerText: "Options",
      width: "140",
      alignment: "Left",
      template: (args) => {
        return (
          <button className="btn btn-sm" onClick={() => handleDelete(args)}>
            <img src={deleteIcon} alt="delete" width={20} height={20} />
          </button>
        );
      },
    },
  ];

  const handleAddNewMember = async (data) => {
    try {
      let responsePromise;
      if (!jobHiringTeam || jobHiringTeam?.guid === "") {
        responsePromise = createTeamForJob({
          guid: jobGuid,
          data: { ...data, name: `${jobData?.title}-team` },
        }).unwrap();
        toast.promise(responsePromise, {
          success: "Team Created successfully",
          loading: "Loading...",
          error: "Something went wrong...!",
        });
      } else {
        responsePromise = updateTeam({
          guid: jobHiringTeam?.guid,
          data: { ...data, name: jobHiringTeam?.name },
        });
        toast.promise(responsePromise, {
          success: "Team Updated successfully",
          loading: "Loading...",
          error: "Something went wrong...!",
        });
      }
    } catch (error) {
      toast.error(error?.data?.Message);
    }
  };

  const handleSaveAndContinue = () => {
    if (members && members.length > 0) {
      navigate(`/my-actions/new-job-posting/interview-plan/${jobGuid}`);
    } else {
      toast.error("Please select team members to continue...!");
      return;
    }
  };

  return (
    <div className="hiring-team custom-card px-0 pb-0">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {/* {(jobDataIsLoading || jobDataIsFetching) && <LoadingSpinner />} */}
      <div className="row gap-2 justify-content-between align-items-center">
        <div className="col-auto px-4">
          <button
            className="btn job-btn"
            onClick={() => setShowModal(!showModal)}
          >
            Add Team Members
          </button>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="py-4">
            <DataTable
              data={members}
              columns={newColumns}
              defaultPaging={true}
              selectable={true}
            />
          </div>
        </div>
      </div>

      <div className="row p-4 justify-content-between align-items-center">
        <div className="col-auto">
          <button
            className="btn back-btn"
            onClick={() =>
              navigate(`/my-actions/new-job-posting/description/${jobGuid}`)
            }
          >
            Back
          </button>
        </div>
        <div className="col-auto">
          <button className="btn continue-btn" onClick={handleSaveAndContinue}>
            Save & Continue
          </button>
        </div>
      </div>

      <AddNewUserModal
        showModal={showModal}
        closeModal={setShowModal}
        confirmSubmit={handleAddNewMember}
        team={jobHiringTeam}
      />
    </div>
  );
}
