import React, { useEffect, useState } from "react";
import "./ScheduleInterview.css";
import {
  ScheduleComponent,
  Day,
  Week,
  TimelineViews,
  Month,
  ViewsDirective,
  ViewDirective,
  Inject,
  popupClose,
} from "@syncfusion/ej2-react-schedule";
import { L10n } from "@syncfusion/ej2-base";
import ArrangeMeetingModal from "../../components/ArrangeMeetingModal/ArrangeMeetingModal";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const ScheduleInterview = () => {
  const [showPopup, setShowPopup] = useState(false);
  const refreshToken = localStorage.getItem("GoogleRefreshToken");

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      try {
        const response = await axios.post(
          "https://oauth2.googleapis.com/token",
          {
            code: codeResponse?.code,
            client_id: process.env.REACT_APP_Google_CLIENT_ID,
            client_secret: process.env.REACT_APP_Google_CLIENT_SECRET,
            redirect_uri: process.env.REACT_APP_Google_REDIRECT_URI,
            grant_type: "authorization_code",
          }
        );
        const { access_token, refresh_token } = response.data;
        localStorage.setItem("GoogleAccessToken", access_token);
        localStorage.setItem("GoogleRefreshToken", refresh_token);
      } catch (error) {}
    },
    onError: (error) => console.log("Login Failed:", error),
    scope: "https://www.googleapis.com/auth/calendar",
    include_granted_scopes: true,
    response_type: "code",
  });

  async function getRefreshTokenFromGoogle() {
    try {
      const response = await axios.post("https://oauth2.googleapis.com/token", {
        client_id: process.env.REACT_APP_Google_CLIENT_ID,
        client_secret: process.env.REACT_APP_Google_CLIENT_SECRET,
        refresh_token: refreshToken,
        grant_type: "refresh_token",
      });

      const { access_token } = response.data;
      localStorage.setItem("GoogleAccessToken", access_token);
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      throw error;
    }
  }

  const handleLogin = async () => {
    try {
      if (!refreshToken) {
        await login();
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  useEffect(() => {
    if (refreshToken) {
      getRefreshTokenFromGoogle();
    } else {
      handleLogin();
    }
  }, []);

  const data = [
    {
      Id: "0628",
      Subject: "Candidate Meeting",
      StartTime: new Date(2024, 6, 1, 9, 0),
      EndTime: new Date(2024, 6, 1, 10, 0),
      IsAllDay: false,
      Status: "Completed",
      Priority: "High",
    },
    {
      Id: "0638",
      Subject: "Candidate Meeting 2",
      StartTime: new Date(2024, 6, 1, 10, 0),
      EndTime: new Date(2024, 6, 1, 11, 0),
      IsAllDay: false,
      Status: "Completed",
      Priority: "High",
    },
  ];

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center py-4">
        <div></div>
        <div>
          <button
            className="primary-button"
            onClick={() => {
              if (!refreshToken) {
                handleLogin();
              } else {
                setShowPopup(true);
              }
            }}
          >
            Arrange Meeting
          </button>
        </div>
      </div>
      <ScheduleComponent
        width="100%"
        height="550px"
        eventSettings={{ dataSource: data }}
        selectedDate={new Date(2024, 5, 1)}
        currentView="Month"
        onBlur={(args) => {
          console.log("celldata", args.target.attributes[1].value);
        }}
      >
        <ViewsDirective>
          <ViewDirective option="Day" />
          <ViewDirective option="Week" />
          <ViewDirective option="Month" />
        </ViewsDirective>
        <Inject services={[Day, Week, Month]} />
      </ScheduleComponent>
      <>
        <ArrangeMeetingModal
          showModal={showPopup}
          closeModal={() => {
            setShowPopup(false);
          }}
        />
      </>
    </div>
  );
};

export default ScheduleInterview;
