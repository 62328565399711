import { useState } from "react";
import "./AssesmentPage.css";
import SettingsPageLayout from "../../../components/SettingsPageLayout/SettingsPageLayout";
import CandidateViewSidebar from "../../../components/CandidateViewSidebar/CandidateViewSidebar";
import CircularProgressBar from "../../../components/common/CircularProgressBar/CircularProgressBar";

const AssesmentPage = () => {
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [comment, setComment] = useState([]);

  const handleAddCommentClick = () => {
    setShowCommentSection(true);
  };

  const handleCancelClick = () => {
    setShowCommentSection(false);
    setComment([]);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <CandidateViewSidebar />
        <div className="col candidate-view-card-container">
          <div className="row mb-2">
            <div className="col">
              <div className="nav-link-container">
                <SettingsPageLayout activeLink={"assesment"} />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <div className="card custom-card p-3">
                <div className="d-flex justify-content-between Assesment-section-one">
                  <div className="d-flex justify-content-between align-items-center section-one">
                    <div className="fw-bold py-1">
                      <div className="p-2">Status</div>
                      <div className="p-2">Date Assigned</div>
                    </div>
                    <div>
                      <div className="p-2">Status</div>
                      <div className="py-1">
                        <input
                          type="date"
                          className="border-0 form-control assigned-date-input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center section-one">
                    <div className="fw-bold py-1">
                      <div className="p-2">URL</div>
                      <div className="p-2">Date Submitted</div>
                    </div>
                    <div>
                      <div className="p-2">
                        <a href="/">https://meet.google.com/ruw-fynm-jfu</a>
                      </div>
                      <div className="py-1">
                        <input
                          type="date"
                          className="border-0 form-control assigned-date-input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <div className="card custom-card p-3">
                <div className="text-secondary fw-bold py-3">
                  <div>
                    <h6>Score Received</h6>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <CircularProgressBar
                      id={1}
                      trackColor={"#E2FBD7"}
                      progressColor={"#34B53A"}
                      value={63}
                    />
                  </div>
                </div>
                <div>
                  <h6 className="text-secondary py-1">Feedback Received</h6>
                  <div className="feedback-section">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Mollitia eius nihil alias qui! Laboriosam, quibusdam
                    voluptatibus dolorem dolorum commodi aspernatur reiciendis,
                    a minus quis modi magni veniam tempora suscipit amet? Lorem
                    ipsum dolor sit amet consectetur adipisicing elit. Cum atque
                    deleniti minima reprehenderit ad iste debitis nostrum
                    voluptatibus obcaecati inventore itaque ipsa, modi error!
                    Velit inventore eos nemo error quas?
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <div className="card custom-card p-3">
                <div className="pt-3">
                  <div className="d-flex align-items-center">
                    <div className="commented-user-image">
                      <img
                        src="https://img.fixthephoto.com/blog/images/gallery/news_image_11368.png"
                        alt="abc"
                      />
                    </div>
                    <div className="px-2 small-text">
                      <h6 className="m-0">Jessica Hunter</h6>
                      <span className="text-secondary">
                        Commented on Assignment
                      </span>
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="feedback-section">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Mollitia eius nihil alias qui! Laboriosam, quibusdam
                      voluptatibus dolorem dolorum commodi aspernatur
                      reiciendis, a minus quis modi magni veniam tempora
                      suscipit amet? Lorem ipsum dolor sit amet consectetur
                      adipisicing elit. Cum atque deleniti minima reprehenderit
                      ad iste debitis nostrum voluptatibus obcaecati inventore
                      itaque ipsa, modi error! Velit inventore eos nemo error
                      quas?
                    </div>
                  </div>
                </div>
                <div className="pt-1">
                  <div>
                    <button
                      className="btn"
                      onClick={() => {
                        handleAddCommentClick();
                      }}
                    >
                      <h6 className="text-secondary small-text m-0">
                        Add Comment
                      </h6>
                    </button>
                  </div>
                  {showCommentSection && (
                    <div className="comment-section pt-3">
                      <textarea
                        className="comment-text-area"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Write your comment here..."
                        rows="2"
                        cols="50"
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "10px",
                          fontSize:"0.9rem"
                        }}
                      >
                        <button
                          className="primary-button"
                          //   onClick={handleSaveClick}
                        >
                          Add Comment
                        </button>
                        <button
                          className="back-btn"
                          onClick={handleCancelClick}
                          style={{ marginLeft: "10px" }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col">
              <div className="card custom-card p-3">
                <div>
                  <h6 className="text-secondary">Upcoming</h6>
                  <div className="d-flex align-items-center">
                    <div className="rounded px-3 upcoming-date-container">
                      <h5 className="fw-bold py-1">Jul</h5>
                      <h5 className="text-danger fw-bold">28</h5>
                    </div>
                    <div className="px-3">
                      <span className="fw-bold">Hr Interview : </span>
                      <span className="text-secondary fw-bold">
                        To contact again in three days September 08, 2024
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssesmentPage;
