import { createEntityAdapter, nanoid } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const adapter = createEntityAdapter({
  selectId: (item) => item?.id,
});

const initialState = adapter.getInitialState();

export const staticDataSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppliedJobInterviewStageStatuses: builder.query({
      query: () => `/applied-job-interview-pipeline-stages/statuses`,
      transformResponse: (response) => {
        const loadedData = response;
        return adapter.setAll(initialState, loadedData);
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "AppliedJobInterviewStageStatuses", id: "LIST" }];
        } else {
          return [
            { type: "AppliedJobInterviewStageStatuses", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "AppliedJobInterviewStageStatuses",
              id,
            })),
          ];
        }
      },
    }),
    getAppliedJobStatuses: builder.query({
      query: () => `/applied-jobs/statuses`,
      transformResponse: (response) => {
        const loadedData = response;
        return adapter.setAll(initialState, loadedData);
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "AppliedJobStatuses", id: "LIST" }];
        } else {
          return [
            { type: "AppliedJobStatuses", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "AppliedJobStatuses",
              id,
            })),
          ];
        }
      },
    }),
    getJobCategories: builder.query({
      query: () => `/jobs/categories`,
      transformResponse: (response) => {
        const loadedData = response;
        return adapter.setAll(initialState, loadedData);
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "JobCategories", id: "LIST" }];
        } else {
          return [
            { type: "JobCategories", id: "LIST" },
            ...result.ids.map((id) => ({
              type: "JobCategories",
              id,
            })),
          ];
        }
      },
    }),
  }),
});

export const {
  useGetAppliedJobInterviewStageStatusesQuery,
  useGetAppliedJobStatusesQuery,
  useGetJobCategoriesQuery,
} = staticDataSlice;
