import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "../../features/ConfigurationPage/EmailTemplate/EmailTemplate.css";
import { useNavigate, useLocation } from "react-router-dom";

const ConfigurationLayout = () => {
  const [activeLink, setActiveLink] = useState("email-templates");
  const navigate = useNavigate();
  const location = useLocation();
  const isEmailTemplatesTab = location.pathname.includes("email-templates");
  const isCompanyProfileTab = location.pathname.includes("company-profile");

  useEffect(() => {
    if (isEmailTemplatesTab) {
      navigate("/configuration/email-templates");
      setActiveLink("email-templates");
    }

    if (isCompanyProfileTab) {
      setActiveLink("company-profile");
      navigate("/configuration/company-profile");
    }
  }, [isEmailTemplatesTab]);

  return (
    <div>
      <div className="settings-page-tabs tab-container">
        <div
          className={`col-6 custom-card filter-link email-template-tab ${
            activeLink === "email-templates" ? "active" : ""
          }`}
          onClick={() => {
            setActiveLink("email-templates");
            navigate("/configuration/email-templates");
          }}
        >
          Email Templates
        </div>
        <div
          className={`col-6 custom-card filter-link company-profile-tab ${
            activeLink === "company-profile" ? "active" : ""
          }`}
          onClick={() => {
            setActiveLink("company-profile");
            navigate("/configuration/company-profile");
          }}
        >
          Company Profile
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default ConfigurationLayout;
