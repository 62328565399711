import React, { useEffect, useRef, useState } from "react";
import "./InterviewPlan.css";
import detailsIcon from "../../../assets/icons/details.svg";
import detailsActiveIcon from "../../../assets/icons/details - active.svg";
import addIcon from "../../../assets/icons/add.svg";
import closeIcon from "../../../assets/icons/close.svg";
import addPersonIcon from "../../../assets/icons/person_add.svg";
import deleteIcon from "../../../assets/icons/deleteIcon.svg";
import deleteActiveIcon from "../../../assets/icons/deleteIcon - active.svg";
import editIcon from "../../../assets/icons/Rounded_buttons_36.svg";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import DeleteConfirmationModal from "../../../components/common/DeleteConfirmationModal/DeleteConfirmationModal";
import AddStageModal from "../../../components/AddStageModal/AddStageModal";
import toast, { Toaster } from "react-hot-toast";
import {
  useAddJobInterviewPipelineStagesMutation,
  useDeleteInterviewPipelineStageMutation,
  useDeleteInterviewPipelineStageQuestionMutation,
  // useGetJobInterviewPipelineStagesQuery,
  useGetStagesQuery,
} from "../newJobPostingSlice";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";

export default function InterviewPlan() {
  const { jobGuid } = useParams();
  const [stages, setStages] = useState([]);
  const [stage, setStage] = useState(stages[0] ?? {});
  const [dragItem, setDragItem] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [addShowModal, setAddShowModal] = useState(false);
  const [isInterviewKitEnabled, setIsInterviewKitEnabled] = useState(false);
  const [isQuestionInputVisible, setIsQuestionInputVisible] = useState(false);
  const [type, setType] = useState("");
  const [deleteStage, setDeleteStage] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [editingQuestionGuid, setEditingQuestionGuid] = useState(null);
  const [editedQuestionText, setEditedQuestionText] = useState("");

  const navigate = useNavigate();

  const {
    data: stagesData,
    isLoading: stagesDataIsLoading,
    isFetching: stagesDataIsFetching,
    isSuccess: stagesDataIsSuccess,
    isError: stagesDataIsError,
    error: stagesDataError,
  } = useGetStagesQuery(jobGuid);

  const [
    addJobInterviewPiplineStages,
    { isLoading: addJobInterviewPiplineStagesIsLoading },
  ] = useAddJobInterviewPipelineStagesMutation();

  const [
    deleteJobInterviewPiplineStage,
    { isLoading: deleteJobInterviewPiplineStageIsLoading },
  ] = useDeleteInterviewPipelineStageMutation();
  const [
    deleteJobInterviewPiplineStageQuestion,
    { isLoading: deleteJobInterviewPiplineStageQuestionIsLoading },
  ] = useDeleteInterviewPipelineStageQuestionMutation();

  useEffect(() => {
    if (stagesData) {
      const data = stagesData?.ids?.map((id) => stagesData.entities[id]);
      if (data?.length > 0) {
        setStages(data);
        setStage(data[0]);
      } else {
        setStages([
          {
            guid: 1,
            name: "Application Review",
            isInterviewKitEnabled: false,
            order: 0,
            questions: [],
            isNew: true,
          },
          {
            guid: 2,
            name: "Pending Offer",
            order: 1,
            isInterviewKitEnabled: false,
            questions: [],
            isNew: true,
          },
          {
            guid: 3,
            name: "Hired",
            isInterviewKitEnabled: false,
            order: 2,
            questions: [],
            isNew: true,
          },
        ]);
        setStage({
          guid: 1,
          name: "Application Review",
          order: 0,
          isInterviewKitEnabled: false,
          questions: [],
          isNew: true,
        });
      }
    }

    if (stagesDataIsError) {
      toast.error(stagesDataError?.data?.Message);
    }
  }, [stagesData, stagesDataIsError]);

  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "CreateLink",
      "Image",
      "UnorderedList",
    ],
    type: "Expand",
  };

  const handleDrop = (index) => {
    const tempArry = [...stages];
    const temp = tempArry.splice(dragItem, 1);
    tempArry.splice(index, 0, ...temp);
    setStages(tempArry);
  };

  const handleAddNewStage = (name) => {
    const isDuplicate = stages.some(
      (stage) => stage.name.toLowerCase() === name.toLowerCase()
    );
    if (isDuplicate) {
      toast.error(
        "Stage name already exists. Please select a unique name or Update the existing stage"
      );
      return;
    }

    const tempArray = [...stages];
    const data = {
      guid: stages.length + 1,
      name,
      isInterviewKitEnabled: false,
      order: stages.length,
      questions: [],
      isNew: true,
    };
    tempArray.push(data);
    setStage(data);
    setStages(tempArray);
    setAddShowModal(!addShowModal);
    toast.success("Stage Added Successfully", {
      duration: 1500,
    });
  };

  const handleDeleteStage = async () => {
    const tempArray = [...stages];
    if (deleteStage.isNew) {
      setStages(tempArray.filter((item) => item !== deleteStage));
      toast.success("Stage Deleted Successfully", {
        duration: 1500,
      });
    } else {
      try {
        const resposePromise = deleteJobInterviewPiplineStage(
          deleteStage.guid
        ).unwrap();
        toast.promise(resposePromise, {
          loading: "Loading...",
          success: "Stage deleted successfully",
          error: "Something went wrong",
        });
      } catch (error) {
        toast.error(error?.data?.Message);
      }
    }
    setShowModal(!showModal);
  };

  const handleSave = async (saveOnly = false) => {
    const filteredStages = stages.filter(
      (stage) => stage.isInterviewKitEnabled && stage.questions.length === 0
    );
    if (filteredStages.length > 0) {
      const errorText = filteredStages.map((stage) => stage.name).join(", ");
      toast.error(
        `In ${errorText} ${
          filteredStages.length === 1 ? "stage" : "stages"
        }  interview kit is enabled but no questions included...! `
      );

      return;
    }
    const formattedStages = stages.map((stage, index) => {
      let updatedStage = { ...stage, order: index };
      if (updatedStage.isNew) {
        const { guid, ...stageWithoutGuid } = updatedStage;
        updatedStage = {
          ...stageWithoutGuid,
          questions: stage.questions.map((q) => {
            if (q?.isNew) return { question: q.question };
            else return q;
          }),
        };
      } else {
        updatedStage = {
          ...updatedStage,
          questions: stage.questions.map((q) => {
            if (q?.isNew) return { question: q.question };
            else return q;
          }),
        };
      }

      return updatedStage;
    });

    const data = {
      data: { jobInterviewPipelineStages: formattedStages },
      guid: jobGuid,
    };
    try {
      const responsePromise = addJobInterviewPiplineStages(data).unwrap();
      toast.promise(responsePromise, {
        loading: "Loading",
        success: "Job Interview pipeline stages added successfully",
        error: "Couldn't save job inteview pipeline stages...!",
      });

      const response = await responsePromise;
      if (!saveOnly) {
        navigate(
          `/my-actions/new-job-posting/application-form/${jobGuid}/document-upload`
        );
      }
    } catch (error) {
      toast.error(error?.data?.Message || "Something went wrong...!");
    }
  };

  const handleAdd = () => {
    if (questionText === "") {
      toast.error("Please enter a question");
      return;
    }
    const data = {
      ...stage,
      questions: [
        ...stage.questions,
        { guid: nanoid(), question: questionText, isNew: true },
      ],
    };
    setStage(data);

    setStages((prev) =>
      prev.map((s) => {
        if (s.guid === stage.guid) {
          return data;
        } else {
          return s;
        }
      })
    );
    setQuestionText("");
  };

  const handleSwitchChange = (value) => {
    if (value) {
      setStage((prev) => ({ ...prev, isInterviewKitEnabled: value }));
      // setIsQuestionInputVisible(value);
    } else {
      setStage((prev) => ({ ...prev, isInterviewKitEnabled: value }));
      setIsQuestionInputVisible(value);
      setEditedQuestionText("");
      setEditingQuestionGuid(null);
    }

    setStages((prevStages) =>
      prevStages.map((s) => {
        if (s.guid === stage.guid) {
          return {
            ...s,
            isInterviewKitEnabled: value,
          };
        } else {
          return s;
        }
      })
    );
  };

  const handleStageSelect = (item) => {
    setStage(item);
    setIsInterviewKitEnabled(false);
  };

  const handleDeleteQuestion = async (guid) => {
    const isNewQuestion = stage.questions.find((q) => q.guid === guid)?.isNew;
    if (isNewQuestion) {
      const data = {
        ...stage,
        questions: stage.questions.filter((q) => q.guid !== guid),
      };
      setStage(data);

      setStages((prev) =>
        prev.map((s) => {
          if (s.guid === stage.guid) {
            return data;
          } else {
            return s;
          }
        })
      );
    } else {
      try {
        const responsePromise =
          deleteJobInterviewPiplineStageQuestion(guid).unwrap();
        toast.promise(responsePromise, {
          success: "Question deleted",
          loading: "Deleting...",
          error: "Something went wrong...!",
        });
      } catch (error) {
        toast.error(error?.data?.Message);
      }
    }
  };

  const handleUpdateQuestion = (guid, newQuestionText) => {
    const updatedQuestions = stage.questions.map((q) => {
      if (q.guid === guid) {
        return { ...q, question: newQuestionText };
      } else {
        return q;
      }
    });

    const updatedStage = {
      ...stage,
      questions: updatedQuestions,
    };

    setStage(updatedStage);

    setStages((prevStages) =>
      prevStages.map((s) => {
        if (s.guid === stage.guid) {
          return updatedStage;
        } else {
          return s;
        }
      })
    );
  };

  const handleEditClick = (question) => {
    setEditingQuestionGuid(question.guid);
    setEditedQuestionText(question.question);
  };

  const handleSaveClick = () => {
    handleUpdateQuestion(editingQuestionGuid, editedQuestionText);
    setEditingQuestionGuid(null);
  };

  const handleCancelClick = () => {
    setEditingQuestionGuid(null);
    setEditedQuestionText("");
  };

  const handleBack = () => {
    navigate(`/my-actions/new-job-posting/hiring-team/${jobGuid}`);
  };

  return (
    <div className="interview-plan">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="custom-card interview-plan-card">
        {stagesDataIsLoading && <LoadingSpinner />}
        <div className="row">
          <div className="col-4 stage-col">
            <p>Interview Stages</p>
            <div className="stage-container">
              {stages.map((item, index) => (
                <div
                  key={item.guid}
                  className={`stages ${
                    stage.guid === item.guid ? "active" : ""
                  }`}
                  onClick={() => handleStageSelect(item)}
                  draggable
                  onDragStart={() => {
                    setStage(item);
                    setDragItem(index);
                  }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={() => {
                    handleDrop(index);
                  }}
                >
                  {item.name}
                  <div className="stage-icons">
                    <button>
                      <img
                        src={
                          stage.guid === item.guid
                            ? detailsActiveIcon
                            : detailsIcon
                        }
                        alt="details"
                        width={20}
                        height={20}
                      />
                    </button>
                    {item.name.trim() !== "Application Review" &&
                    item.name.trim() !== "Pending Offer" &&
                    item.name.trim() !== "Hired" ? (
                      <button>
                        <img
                          src={
                            stage.guid === item.guid
                              ? deleteActiveIcon
                              : deleteIcon
                          }
                          alt="delete"
                          width={18}
                          height={18}
                          onClick={() => {
                            setType("stage");
                            setShowModal(!showModal);
                            setDeleteStage(item);
                          }}
                        />
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
              <div
                className="stages add"
                onClick={() => setAddShowModal(!addShowModal)}
              >
                {/* <img src={addIcon} alt="details" width={20} height={20} /> Add */}
                <span className="add-icon"></span>
                Add another Pipleline stage
              </div>
            </div>
          </div>

          <div className="col-8">
            <div className="detail-view">
              <p className="mb-4 stage-title">{stage.name}</p>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex align-items-center">
                  <label className="me-3">Interview Kit</label>
                  <div className="form-check form-switch d-inline-block">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      checked={stage.isInterviewKitEnabled}
                      onChange={(e) => handleSwitchChange(e.target.checked)}
                    />
                  </div>
                </div>
                {stage.isInterviewKitEnabled && (
                  <button
                    className="btn add-questions d-flex gap-1 align-items-center border"
                    onClick={() =>
                      setIsQuestionInputVisible(!isQuestionInputVisible)
                    }
                  >
                    <img
                      src={isQuestionInputVisible ? closeIcon : addIcon}
                      alt="add"
                      width={20}
                      height={20}
                    />
                    {isQuestionInputVisible ? "Cancel" : "Add Question"}
                  </button>
                )}
              </div>
              {stage.isInterviewKitEnabled && isQuestionInputVisible && (
                <div className="row align-items-center gap-1 mb-3">
                  <div className="col-12">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Enter a question"
                        placeholder="Enter a question"
                        value={questionText}
                        onChange={(e) => setQuestionText(e.target.value)}
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleAdd}
                      >
                        Add
                      </button>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => setQuestionText("")}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="row mt-2">
                <div className="col-12 question-container">
                  {stage?.questions?.length > 0
                    ? stage.questions.map((question, index) => (
                        <div
                          key={`question-${index}`}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div className="questions flex-grow-1">
                            {stage.isInterviewKitEnabled &&
                            editingQuestionGuid === question.guid ? (
                              <input
                                type="text"
                                value={editedQuestionText}
                                onChange={(e) =>
                                  setEditedQuestionText(e.target.value)
                                }
                                className="form-control"
                              />
                            ) : (
                              <p>{`${index + 1}. ${question.question}`}</p>
                            )}
                          </div>
                          <div className="col-auto d-flex align-items-center">
                            {stage.isInterviewKitEnabled &&
                            editingQuestionGuid === question.guid ? (
                              <>
                                <button
                                  className="btn me-1"
                                  onClick={handleSaveClick}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn"
                                  onClick={handleCancelClick}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              stage.isInterviewKitEnabled && (
                                <>
                                  <button
                                    className="btn me-1"
                                    onClick={() => handleEditClick(question)}
                                  >
                                    <img
                                      src={editIcon}
                                      alt="edit"
                                      width={25}
                                      height={25}
                                    />
                                  </button>
                                  <button
                                    className="btn"
                                    onClick={() =>
                                      handleDeleteQuestion(question.guid)
                                    }
                                  >
                                    <img
                                      src={deleteIcon}
                                      alt="delete"
                                      width={20}
                                      height={20}
                                    />
                                  </button>
                                </>
                              )
                            )}
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <button className="btn back-btn" onClick={handleBack}>
              Back
            </button>
          </div>
          <div className="col-auto">
            <button
              className="btn back-btn me-2"
              onClick={() => handleSave(true)}
            >
              Save
            </button>
            <button
              className="btn continue-btn"
              onClick={() => handleSave(false)}
            >
              Save & Continue
            </button>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        type={type}
        showModal={showModal}
        closeModal={setShowModal}
        confirmDelete={handleDeleteStage}
      />
      <AddStageModal
        title={"Stage"}
        showModal={addShowModal}
        closeModal={setAddShowModal}
        confirmSubmit={(value) => handleAddNewStage(value)}
      />
    </div>
  );
}
