import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";

export const useHandleAttachmentDownload = () => {
  const token = useSelector(selectCurrentToken);
  const [attachmentLinks, setAttachmentLinks] = useState([]);

  const handleAttachmentDownload = async (data) => {
    if (data) {
      const file = attachmentLinks?.filter(
        (at) => at.fileName === data.fileName
      );

      if (file.length !== 0) {
        file[0].link.click();
      } else {
        if (token) {
          const axiosConfig = {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };
          const promise = axios.get(
            `${
              window.location.hostname === "localhost"
                ? process.env.REACT_APP_API_URL
                : process.env.PUBLIC_URL
            }/v1/attachments/${data.guid}`,
            axiosConfig
          );

          toast.promise(promise, {
            loading: "Downloading...",
            success: (response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;

              link.setAttribute("download", data.fileName);
              document.body.appendChild(link);
              setAttachmentLinks([
                ...attachmentLinks,
                {
                  fileName: data.fileName,
                  link: link,
                },
              ]);
              link.click();

              return "Downloaded Successfully";
            },
            error: "Error when downloading",
          });
        }
      }
    }
  };
  return { handleAttachmentDownload };
};
